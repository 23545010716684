import { Mark } from '../context/product_context'
import { get, post } from './generic'

interface MarkApiData {
    idMarca: number
    nome: string
}

function ParseMark(data: MarkApiData): Mark {
    return {
        id: data.idMarca,
        name: data.nome,
    }
}

export async function getAllMarks(): Promise<Mark[]> {
    const res = await get('marca')
    return res.data.data.map((mark: MarkApiData) => ParseMark(mark))
}

export async function getMarkById(id: number): Promise<Mark> {
    const res = await get(`marca/${id}`)
    return ParseMark(res.data.data.brand)
}

export async function createMark(name: string): Promise<Mark> {
    const res = await post(
        'marca',
        { nome: name },
        {
            useRecaptcha: true,
        }
    )
    return ParseMark(res.data.data.brand)
}

import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
    craetePaymentMethod,
    getPaymentMethodById,
    listPaymentMethods,
    updatePaymentMethod,
} from '../services/payment'
import { useAccount } from './account_context'
import { useLoading } from './loading_context'
import { useSnack } from './snack_context'

export interface PaymentMethodInfo {
    id: number
    name: string
    isActive: boolean
}

export interface CraetePaymentMethodInfo extends Omit<PaymentMethodInfo, 'id'> {
    id?: number
    isEditing: boolean
}

export interface PaymentMethodError {
    name: string
}

const paymentMethodInfoDefault: CraetePaymentMethodInfo = {
    name: '',
    isActive: true,
    isEditing: false,
}

const paymentMethodErrorDefault: PaymentMethodError = {
    name: '',
}

export interface PaymentMethodContextInterface {
    paymentMethods: PaymentMethodInfo[]
    createPaymentMethodInfo: CraetePaymentMethodInfo
    setCreatePaymentMethodInfo: Dispatch<
        SetStateAction<CraetePaymentMethodInfo>
    >
    paymentMethodError: PaymentMethodError
    setPaymentMethodError: Dispatch<SetStateAction<PaymentMethodError>>
    validate(): boolean
    handleCreateNewPaymentMethod(): Promise<void>
    startNewPaymentMethod(): void
    editPaymentMethod(id: number): Promise<void>
    getPaymentMethods(): Promise<void>
}

const paymentMethodContextDefault: PaymentMethodContextInterface = {
    paymentMethods: [],
    createPaymentMethodInfo: paymentMethodInfoDefault,
    setCreatePaymentMethodInfo: () => {},
    paymentMethodError: paymentMethodErrorDefault,
    setPaymentMethodError: () => {},
    validate: () => false,
    handleCreateNewPaymentMethod: () => Promise.resolve(),
    startNewPaymentMethod: () => {},
    editPaymentMethod: () => Promise.resolve(),
    getPaymentMethods: () => Promise.resolve(),
}

const PaymentMethodContext = createContext<PaymentMethodContextInterface>(
    paymentMethodContextDefault
)

export function usePaymentMethod() {
    return useContext(PaymentMethodContext)
}

interface Props {
    children: ReactNode
}

export function PaymentMethodContextProvider({ children }: Props) {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodInfo[]>(
        []
    )
    const [createPaymentMethodInfo, setCreatePaymentMethodInfo] =
        useState<CraetePaymentMethodInfo>(paymentMethodInfoDefault)
    const [paymentMethodError, setPaymentMethodError] =
        useState<PaymentMethodError>(paymentMethodErrorDefault)
    const { accountInfo } = useAccount()

    useEffect(() => {
        if (!accountInfo) return
        if (
            accountInfo?.isMaster ||
            accountInfo.rules.findIndex(
                (r) =>
                    r.group.toLowerCase() === 'métodos de pagamento' &&
                    r.name.toLowerCase() === 'listar'
            ) !== -1
        ) {
            getPaymentMethods()
        }
    }, [accountInfo])

    const getPaymentMethods = async () => {
        loading.show()
        try {
            const res = await listPaymentMethods()
            setPaymentMethods(res)
        } catch (error) {
            snack.error('Erro ao carregar métodos de pagamento')
        }
        loading.hide()
    }

    const validate = (): boolean => {
        let isOk = true
        const newCashierError = { ...paymentMethodErrorDefault }

        if (!createPaymentMethodInfo.name) {
            newCashierError.name = 'Campo obrigatório'
            isOk = false
        }

        setPaymentMethodError({ ...newCashierError })
        return isOk
    }

    const handleCreateNewPaymentMethod = async () => {
        loading.show()
        try {
            if (!createPaymentMethodInfo.isEditing)
                await craetePaymentMethod({
                    nome: createPaymentMethodInfo.name,
                    flag_ativo: Number(createPaymentMethodInfo.isActive),
                })
            else
                await updatePaymentMethod({
                    id_metodo_pagamento: createPaymentMethodInfo.id ?? 0,
                    nome: createPaymentMethodInfo.name,
                    flag_ativo: Number(createPaymentMethodInfo.isActive),
                })
            getPaymentMethods()
            snack.success(
                `Método de pagamento ${
                    !createPaymentMethodInfo.isEditing ? 'criado' : 'atualizado'
                } com sucesso!`
            )
            navigate('/system/payment-method')
            setCreatePaymentMethodInfo({ ...paymentMethodInfoDefault })
            setPaymentMethodError({ ...paymentMethodErrorDefault })
        } catch (error) {
            snack.connectionFail()
        }
        loading.hide()
    }

    const startNewPaymentMethod = () => {
        setCreatePaymentMethodInfo({ ...paymentMethodInfoDefault })
        setPaymentMethodError({ ...paymentMethodErrorDefault })
    }

    const editPaymentMethod = async (id: number) => {
        try {
            const paymentMethod = await getPaymentMethodById(id)

            setCreatePaymentMethodInfo((prev) => ({
                ...paymentMethod,
                isEditing: true,
            }))
            setPaymentMethodError({ ...paymentMethodErrorDefault })
        } catch (error) {
            console.log(error)
            snack.error('Método de pagamento não encontrado')
            throw new Error('Payment method not found')
        }
    }

    return (
        <PaymentMethodContext.Provider
            value={{
                paymentMethods,
                createPaymentMethodInfo,
                setCreatePaymentMethodInfo,
                paymentMethodError,
                setPaymentMethodError,
                validate,
                handleCreateNewPaymentMethod,
                startNewPaymentMethod,
                editPaymentMethod,
                getPaymentMethods,
            }}
        >
            {children}
        </PaymentMethodContext.Provider>
    )
}

import EmailIcon from '@mui/icons-material/Email'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    styled,
} from '@mui/material'
import { Stack } from '@mui/system'
import { useEffect } from 'react'
import { useConfigs } from '../../context/configs_context'
import { SaleInfo } from '../../context/sale_context'
import { getFileEndpoint } from '../../services/media'
import {
    getDateInDDMMYYYYFormat,
    getDateString,
    maskCurrency,
} from '../../utils/maks'
import {
    ServiceInfo,
    ServiceStatusEnum,
    ServiceStatusLabel,
} from '../../context/service_context'
const writtenNumber = require('written-number')

const StyledTableCell = styled(TableCell)(({}) => ({
    color: 'text.primary',
    border: 'none',
    padding: '2px 4px',
}))

interface ServiceNoteProps {
    service: ServiceInfo
}

const ContactIcon: Record<string, JSX.Element> = {
    Facebook: <FacebookIcon />,
    Instagram: <InstagramIcon />,
    Whatsapp: <WhatsAppIcon />,
    Endereço: <LocationOnIcon />,
    Telefone: <LocalPhoneIcon />,
    Email: <EmailIcon />,
}

function ServiceNote({ service }: ServiceNoteProps) {
    const { configs, contactTypes, base64Logo } = useConfigs()

    return (
        <Stack
            direction={'column'}
            width={'100%'}
            px={'20px'}
            mt={'10px'}
            height={'calc(50vh - 20px)'}
        >
            <Stack direction={'row'} width={'100%'}>
                <Stack
                    direction={'column'}
                    border={'1px solid black'}
                    borderRight={'0'}
                    borderRadius={'12px'}
                    width={'100%'}
                >
                    <Stack direction={'row'} p={'10px'}>
                        <Box
                            component={'img'}
                            src={base64Logo}
                            width={'240px'}
                            height={'120px'}
                            zIndex={'-1'}
                        />
                        <Stack textAlign={'center'} flex={'1'} px={'50px'}>
                            <Typography
                                fontSize={'18px'}
                                sx={{ wordBreak: 'break-word' }}
                            >
                                {configs.companyName}
                            </Typography>
                            <Typography
                                fontSize={'12px'}
                                sx={{ wordBreak: 'break-word' }}
                                fontStyle={'italic'}
                                whiteSpace={'pre-line'}
                            >
                                {`${configs.companySlogan}`}
                            </Typography>
                            <Typography
                                fontSize={'11px'}
                                sx={{ wordBreak: 'break-word' }}
                            >
                                {contactTypes
                                    .filter((ct) =>
                                        configs.contacts.find(
                                            (c) => c.contactType.id === ct.id
                                        )
                                    )
                                    .map(
                                        (ct) =>
                                            `${ct.name}: ${configs.contacts
                                                .filter(
                                                    (c) =>
                                                        c.contactType.id ===
                                                        ct.id
                                                )
                                                .map((c) => c.value)
                                                .join(', ')}`
                                    )
                                    .join('; ')}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                width={'100%'}
                border={'1px solid black'}
                borderTop={'0'}
                borderRadius={'12px'}
                direction={'column'}
                padding={'10px'}
            >
                <Typography fontSize={'14px'}>
                    Fecha:{' '}
                    {getDateString({
                        date: service.date,
                        hour: service.hour,
                        configs: configs,
                        isSummerTime: service.isSummerTime ?? false,
                    })}
                </Typography>
                <Stack direction={'row'}>
                    <Typography fontSize={'14px'} width={'70%'}>
                        Nombre: {service.client?.name || '---'}
                    </Typography>
                    <Typography fontSize={'14px'}>
                        Documento: {service.client?.document || '---'}
                    </Typography>
                </Stack>
                <Stack direction={'row'}>
                    <Typography fontSize={'14px'} width={'70%'}>
                        Telefonos:{' '}
                        {service.client?.phoneNumbers.join(', ') || '---'}
                    </Typography>
                    <Typography fontSize={'14px'}>
                        Dirección: {service.client?.address || '---'}
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                width={'100%'}
                border={'1px solid black'}
                borderTop={'0'}
                borderRadius={'12px'}
                direction={'column'}
                flex={'1'}
                p={'5px'}
            >
                <Typography>
                    <Box component={'span'} fontWeight={'bold'}>
                        Tipo de servicio:
                    </Box>{' '}
                    {service.serviceType?.name}
                </Typography>
                <Typography>
                    <Box component={'span'} fontWeight={'bold'}>
                        Fecha entrega:
                    </Box>{' '}
                    {service.finalDate
                        ? getDateInDDMMYYYYFormat(service.finalDate)
                        : '---'}
                </Typography>
                <Typography>
                    <Box component={'span'} fontWeight={'bold'}>
                        Estado del servicio:
                    </Box>{' '}
                    {ServiceStatusLabel[service.serviceStatus]}
                </Typography>
                <Typography fontWeight={'bold'}>Descripción:</Typography>
                <Typography>{service.description ?? '---'}</Typography>
            </Stack>
            <Stack
                width={'100%'}
                border={'1px solid black'}
                borderTop={'0'}
                borderRadius={'12px'}
                pl={'10px'}
                pb={'5px'}
            >
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Typography>
                        Total a pagar: {service.coin?.symbol}{' '}
                        <Box component={'span'} fontSize={'12px'}>
                            (En letras)
                        </Box>{' '}
                        {writtenNumber(service.value, { lang: 'es' })}{' '}
                    </Typography>
                    <Typography
                        borderLeft={'1px solid black'}
                        borderBottom={'1px solid black'}
                        p={'5px'}
                    >
                        {maskCurrency(service.value, service.coin)}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

interface Props {
    services: ServiceInfo[]
    handleClose(): void
}

export function ServiceNoteDialog({ handleClose, services }: Props) {
    useEffect(() => {
        setTimeout(() => {
            window.print()
            handleClose()
        }, 100)
    }, [])

    return (
        <Box className="printable">
            <Stack direction={'column'}>
                {services.map((service, index) => (
                    <>
                        <Box marginTop={index === 0 ? '-70px' : '10px'}>
                            <ServiceNote service={service} />
                        </Box>
                    </>
                ))}
            </Stack>
        </Box>
    )
}

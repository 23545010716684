import { AccountInfo, AccountRule } from '../context/account_context'
import { ListReturn } from '../utils/maks'
import { BranchApiData, parseBranch } from './branch'
import { get, post, put } from './generic'

interface RuleApiData {
    id_permissao: number
    nome: string
    grupo: string
}

export interface AccountApiData {
    id_usuario: number
    nome: string
    username: string
    usuario_permissao: {
        id_permissao: number
        id_usuario: number
    }[]
    flag_master: number
    flag_ativo: number
    flag_login_face: number
    id_filial: number | null
    path_face: string | null
    filial: BranchApiData | null
}

export interface CreateAccountInterface {
    nome: string
    username: string
    senha?: string
    flag_ativo: number
    id_permissions: number[]
    flag_login_face: number
    path_face: string | null
    id_filial_master?: number | null
}

export interface UpdateAccountInterface
    extends Partial<CreateAccountInterface> {
    id_usuario: number
}

export interface ListAccountsInterface {
    page: number
    rows: number
    search?: string
    id_filial?: number[]
}

function parseRule(data: RuleApiData): AccountRule {
    return {
        id: data.id_permissao,
        name: data.nome,
        group: data.grupo,
    }
}

function parseAccount(data: AccountApiData, rules: AccountRule[]): AccountInfo {
    return {
        id: data.id_usuario,
        name: data.nome,
        username: data.username,
        password: '',
        cpassword: '',
        rules: data.usuario_permissao.map(
            (p) => rules.find((r) => r.id === p.id_permissao) as AccountRule
        ),
        isMaster: Boolean(data.flag_master),
        isActive: Boolean(data.flag_ativo),
        loginWithImage: Boolean(data.flag_login_face),
        image: data.path_face
            ? {
                  content: data.path_face,
                  file: new File([], data.path_face),
                  isBase64: false,
              }
            : null,
        branch: data.filial ? parseBranch(data.filial) : null,
    }
}

export class UsernameAlreadyInUseError extends Error {}

export async function listAccountRules(): Promise<AccountRule[]> {
    const res = await get('permissions')
    return res.data.data.map((data: RuleApiData) => parseRule(data))
}

export async function createAccount(
    data: CreateAccountInterface
): Promise<number> {
    try {
        const res = await post('user', data, {
            useRecaptcha: true,
        })
        return res.data.data.user.id_usuario
    } catch (error: any) {
        if (
            error?.status === 409 &&
            error?.data.data.message.includes(
                'User with username already exists'
            )
        ) {
            throw new UsernameAlreadyInUseError()
        }
        throw error
    }
}

export async function updateAccount(
    data: UpdateAccountInterface
): Promise<void> {
    try {
        await put('user', data, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (
            error?.status === 409 &&
            error?.data.data.message.includes(
                'User with username already exists'
            )
        ) {
            throw new UsernameAlreadyInUseError()
        }
        throw error
    }
}

export async function listAccounts(
    data: ListAccountsInterface,
    rules: AccountRule[]
): Promise<ListReturn<AccountInfo>> {
    const res = await post('users', data)
    return {
        data: res.data.data.data.map((data: AccountApiData) =>
            parseAccount(data, rules)
        ),
        count: res.data.data.count,
    }
}

export async function getAccountById(
    id: number,
    rules: AccountRule[]
): Promise<AccountInfo> {
    const res = await post('user/specific', {
        filter: [{ name: 'id_usuario', value: [id] }],
    })
    return parseAccount(res.data.data.user[0], rules)
}

export async function getLoggedUser(
    rules: AccountRule[]
): Promise<AccountInfo> {
    const res = await get('user/logged')
    return parseAccount(res.data.data.user[0], rules)
}

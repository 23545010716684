import { SubGroup } from '../context/product_context'
import { get, post } from './generic'

interface SubGroupApiData {
    id_sub_grupo: number
    nome: string
    id_grupo: number
}

function ParseSubGroup(data: SubGroupApiData): SubGroup {
    return {
        id: data.id_sub_grupo,
        name: data.nome,
        groupId: data.id_grupo,
    }
}

export async function getSubGroupsByGroupId(id: number): Promise<SubGroup[]> {
    const res = await get(`sub-grupo/${id}`)
    return res.data.data.map((sg: SubGroupApiData) => {
        return ParseSubGroup(sg)
    })
}

export async function getSubGroupById(id: number): Promise<SubGroup> {
    const res = await post('sub-grupo/specific', {
        filter: [
            {
                name: 'id_sub_grupo',
                value: [id],
            },
        ],
    })
    return ParseSubGroup(res.data.data.group[0])
}

export async function createSubGroup(
    name: string,
    groupId: number
): Promise<SubGroup> {
    const res = await post(
        'sub-grupo',
        {
            nome: name,
            id_grupo: groupId,
        },
        {
            useRecaptcha: true,
        }
    )
    return ParseSubGroup(res.data.data.sub_group)
}

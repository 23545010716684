import axios from 'axios'

export function getFileEndpoint(filename: string): string {
    const baseURL =
        process.env.REACT_APP_MODE === 'cloud'
            ? process.env.REACT_APP_API_ENDPOINT
            : `http://${window.location.hostname}:4000/api`

    return `${baseURL}/media/file?filename=${filename.replaceAll(
        '&',
        '%26'
    )}&authorization=${localStorage.getItem('token')}`
}

export async function downloadFile(filename: string) {
    const res = await axios.get(getFileEndpoint(filename), {
        responseType: 'blob',
    })

    const href = URL.createObjectURL(res.data)

    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', filename.split('/').pop() ?? 'download')
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(href)
}

export async function getFileInBase64(filename: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        axios
            .get(getFileEndpoint(filename), {
                responseType: 'blob',
            })
            .then((response) => {
                const reader = new FileReader()
                reader.readAsDataURL(response.data)
                reader.onloadend = function () {
                    resolve(reader.result as string)
                }
            })
            .catch((err) => reject(err))
    })
}

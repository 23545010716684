import {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
    ReactNode,
} from 'react'
import { useLoading } from './loading_context'
import {
    SupplierDocumentAlreadyInUseError,
    SupplierEmailAlreadyInUseError,
    createSupplier,
    getSupplierById,
    updateSupplier,
} from '../services/supplier'
import { useSnack } from './snack_context'
import { useNavigate } from 'react-router-dom'
import { validateEmail, validateRuc } from '../utils/maks'

export enum NationalityEnum {
    Foregein = 0,
    Native = 1,
}

export interface SupplierInfo {
    id?: number
    name: string
    document: string
    email: string
    address: string
    phoneNumbers: string[]
    nationality: NationalityEnum
    isActive: boolean
}

export interface CreateSupplierInfo extends SupplierInfo {
    step: number
    isEditing: boolean
}

export interface SupplierErrorInfo {
    name: string
    document: string
    email: string
    address: string
    phoneNumbers: string[]
}

const createSupplierInfoDefault: CreateSupplierInfo = {
    name: '',
    document: '',
    email: '',
    address: '',
    nationality: NationalityEnum.Native,
    phoneNumbers: [''],
    step: 1,
    isEditing: false,
    isActive: true,
}

const supplierErrorDefault: SupplierErrorInfo = {
    name: '',
    document: '',
    email: '',
    address: '',
    phoneNumbers: [''],
}

export interface SupplierContextInterface {
    supplierInfo: CreateSupplierInfo
    setSupplierInfo: Dispatch<SetStateAction<CreateSupplierInfo>>
    supplierError: SupplierErrorInfo
    setSupplierError: Dispatch<SetStateAction<SupplierErrorInfo>>
    validate(): boolean
    handleCreateNewSupplier(): Promise<SupplierInfo | null>
    startNewSupplier(): void
    editSupplier(id: number): Promise<void>
}

const supplierContext = createContext<SupplierContextInterface>({
    supplierInfo: createSupplierInfoDefault,
    setSupplierInfo: () => {},
    supplierError: supplierErrorDefault,
    setSupplierError: () => {},
    validate: () => {
        return false
    },
    handleCreateNewSupplier: async () => {
        return null
    },
    startNewSupplier: () => {},
    editSupplier: async () => {},
})

export function useSupplier() {
    return useContext(supplierContext)
}

interface Props {
    children: ReactNode
}

export const SupplierProvider = ({ children }: Props) => {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [supplierInfo, setSupplierInfo] = useState<CreateSupplierInfo>(
        createSupplierInfoDefault
    )
    const [supplierError, setSupplierError] =
        useState<SupplierErrorInfo>(supplierErrorDefault)

    const startNewSupplier = () => {
        setSupplierInfo({ ...createSupplierInfoDefault })
        setSupplierError({ ...supplierErrorDefault })
    }

    const editSupplier = async (id: number) => {
        try {
            const supplier = await getSupplierById(id)

            setSupplierInfo((prev) => ({
                ...supplier,
                step: 1,
                isEditing: true,
            }))
            setSupplierError({ ...supplierErrorDefault })
        } catch (error) {
            console.log(error)
            snack.error('Fornecedor não encontrado')
            throw new Error('Supplier not found')
        }
    }

    const validate = (): boolean => {
        let isOk = true
        const newSupplierErrorInfo = { ...supplierErrorDefault }

        if (!supplierInfo.name) {
            isOk = false
            newSupplierErrorInfo.name = 'Campo obrigatório'
        }
        if (!supplierInfo.document) {
            isOk = false
            newSupplierErrorInfo.document = 'Campo obrigatório'
        }
        if (supplierInfo.document && !validateRuc(supplierInfo.document)) {
            isOk = false
            newSupplierErrorInfo.document = 'Documento inválido'
        }
        if (!supplierInfo.email) {
            isOk = false
            newSupplierErrorInfo.email = 'Campo obrigatório'
        }
        if (supplierInfo.email && !validateEmail(supplierInfo.email)) {
            isOk = false
            newSupplierErrorInfo.email = 'Email inválido'
        }
        if (!supplierInfo.address) {
            isOk = false
            newSupplierErrorInfo.address = 'Campo obrigatório'
        }
        if (!supplierInfo.phoneNumbers[0]) {
            isOk = false
            newSupplierErrorInfo.phoneNumbers[0] = 'Campo obrigatório'
        }

        for (let i = 0; i < supplierInfo.phoneNumbers.length; i++) {
            if (supplierInfo.phoneNumbers[i].length > 15) {
                isOk = false
                newSupplierErrorInfo.phoneNumbers[i] =
                    'Deve conter menos de 15 caracteres'
            }
        }

        setSupplierError({ ...newSupplierErrorInfo })
        return isOk
    }

    const handleCreateNewSupplier = async (): Promise<SupplierInfo | null> => {
        let supplier: SupplierInfo | null = null
        loading.show()
        try {
            const body = {
                nome: supplierInfo.name,
                numero_documento: supplierInfo.document,
                email: supplierInfo.email,
                endereco_desc: supplierInfo.address,
                flag_nacionalidade: supplierInfo.nationality,
                telefone: supplierInfo.phoneNumbers,
                flag_ativo: supplierInfo.isActive ? 1 : 0,
            }

            if (!supplierInfo.isEditing) {
                const id = await createSupplier(body)
                snack.success('Fornecedor registrado com sucesso')
                supplier = {
                    ...supplierInfo,
                    id: id,
                }
            } else {
                await updateSupplier({
                    ...body,
                    id_fornecedor: supplierInfo.id ?? 0,
                })
                snack.success('Fornecedor atualizado com sucesso')
                supplier = {
                    ...supplierInfo,
                }
            }
        } catch (error) {
            const newSupplierError = { ...supplierErrorDefault }
            if (error instanceof SupplierEmailAlreadyInUseError) {
                newSupplierError.email = 'Este email já está em uso'
                setSupplierError(newSupplierError)
                setSupplierInfo((prev) => ({
                    ...prev,
                    step: 1,
                }))
            } else if (error instanceof SupplierDocumentAlreadyInUseError) {
                newSupplierError.document = 'Este documento já está em uso'
                setSupplierError(newSupplierError)
                setSupplierInfo((prev) => ({
                    ...prev,
                    step: 1,
                }))
            } else snack.error('Erro ao criar fornecedor!')
        }
        loading.hide()
        return supplier
    }

    return (
        <supplierContext.Provider
            value={{
                supplierInfo,
                setSupplierInfo,
                supplierError,
                setSupplierError,
                validate,
                handleCreateNewSupplier,
                startNewSupplier,
                editSupplier,
            }}
        >
            {children}
        </supplierContext.Provider>
    )
}

import { DiscountInfo, ProductInDiscount } from '../context/discount_context'
import { ListReturn } from '../utils/maks'
import { get, post, put } from './generic'
import { ParseProduct, ProductApiData } from './product'

interface CraeteDiscountInterface {
    titulo: string
    data_inicial: string
    data_final: string
    flag_ativo: number
    produtos: {
        id_produtos: number[]
        valor_desconto: number
    }[]
}

export interface UpdateDiscountInterface {
    id_desconto: number
    flag_ativo: number
}

export interface DiscountApiData {
    id_desconto: number
    titulo: string
    data_inicial: string
    data_final: string
    flag_ativo: number
    descontoProdutos: {
        id_desconto_produto: number
        id_desconto: number
        id_produto: number
        produto: ProductApiData
        valor_desconto: string
    }[]
    data_ativa: boolean
    data: string
    hora: string
    flag_horario_verao: number
}

interface ListDiscountsInputInterface {
    skip?: number
    take?: number
    id_caixa?: number[]
    id_usuario?: number[]
    search?: string
    flag_ativo?: number
    data_ativa?: number
    data_inicial?: string
    data_final?: string
}

export function parseDiscount(data: DiscountApiData): DiscountInfo {
    const discountProducts: ProductInDiscount[] = []
    data.descontoProdutos.forEach((descontoProduto) => {
        const foundDiscountProducts = discountProducts.find(
            (pd) => pd.discount === Number(descontoProduto.valor_desconto) * 100
        )
        if (!foundDiscountProducts)
            discountProducts.push({
                id: descontoProduto.id_desconto_produto,
                products: [ParseProduct(descontoProduto.produto)],
                discount: Number(descontoProduto.valor_desconto) * 100,
            })
        else
            foundDiscountProducts.products.push(
                ParseProduct(descontoProduto.produto)
            )
    })

    return {
        id: data.id_desconto,
        title: data.titulo,
        iniD: new Date(`${data.data_inicial} 00:00:00`),
        endD: new Date(`${data.data_final} 00:00:00`),
        isActive: Boolean(data.flag_ativo),
        discountProducts: discountProducts,
        activeDate: data.data_ativa,
        date: data.data,
        hour: data.hora,
        isSummerTime: Boolean(data.flag_horario_verao),
    }
}

export async function createDiscount(
    data: CraeteDiscountInterface
): Promise<void> {
    await post('discount', data, {
        useRecaptcha: true,
    })
}

export async function updateDiscount(
    data: UpdateDiscountInterface
): Promise<void> {
    await put('discount', data, {
        useRecaptcha: true,
    })
}

export async function listDiscounts(
    data: ListDiscountsInputInterface
): Promise<ListReturn<DiscountInfo>> {
    const res = await post('discounts', data)
    return {
        data: res.data.data.data.map(parseDiscount),
        count: res.data.data.count,
    }
}

import { NationalityEnum, SupplierInfo } from '../context/supplier_context'
import { ListReturn } from '../utils/maks'
import { get, post, put } from './generic'

export class SupplierEmailAlreadyInUseError extends Error {
    constructor() {
        super()
    }
}

export class SupplierDocumentAlreadyInUseError extends Error {
    constructor() {
        super()
    }
}

export interface CreateSupplierInterface {
    nome: string
    numero_documento: string
    email: string
    flag_ativo: number
    telefone: string[]
    endereco_desc: string
    flag_nacionalidade: number
}

export interface UpdateSupplierInterface
    extends Partial<CreateSupplierInterface> {
    id_fornecedor: number
}

export interface ListSuppliersInterface {
    skip: number
    take: number
    search?: string
}

export interface SupplierApiData {
    nome: string
    numero_documento: string
    email: string
    id_fornecedor: number
    flag_ativo: number
    id_endereco2: {
        flag_nacionalidade: number
        id_endereco: number
        nome: string
    }
    telefone_fornecedores: {
        id_fornecedor: number
        id_telefone: number
        telefone: string
    }[]
}

export function parseSupplier(data: SupplierApiData): SupplierInfo {
    return {
        name: data.nome,
        document: data.numero_documento,
        email: data.email,
        address: data.id_endereco2 ? data.id_endereco2.nome : '',
        nationality: data.id_endereco2
            ? data.id_endereco2.flag_nacionalidade
            : NationalityEnum.Native,
        phoneNumbers: data.telefone_fornecedores?.length
            ? data.telefone_fornecedores.map((telefone) => telefone.telefone)
            : [''],
        id: data.id_fornecedor,
        isActive: Boolean(data.flag_ativo),
    }
}

export async function createSupplier(
    infos: CreateSupplierInterface
): Promise<number> {
    try {
        const res = await post('supplier', infos, {
            useRecaptcha: true,
        })
        return res.data.data.supplier.id_fornecedor
    } catch (error: any) {
        if (error.data.data.message.includes('email already exists'))
            throw new SupplierEmailAlreadyInUseError()
        if (error.data.data.message.includes('document already exists'))
            throw new SupplierDocumentAlreadyInUseError()
        throw error
    }
}

export async function updateSupplier(
    infos: UpdateSupplierInterface
): Promise<void> {
    try {
        await put('supplier', infos, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (error.data.data.message.includes('email already exists'))
            throw new SupplierEmailAlreadyInUseError()
        if (error.data.data.message.includes('document already exists'))
            throw new SupplierDocumentAlreadyInUseError()
        throw error
    }
}

export async function listSuppliers(
    data: ListSuppliersInterface
): Promise<ListReturn<SupplierInfo>> {
    const res = await post('supplier/list', data)
    return {
        data: res.data.data.data.map((data: SupplierApiData) =>
            parseSupplier(data)
        ),
        count: res.data.data.count,
    }
}

export async function getSupplierById(id: number): Promise<SupplierInfo> {
    const res = await post('supplier/specific', {
        filter: [
            {
                name: 'id_fornecedor',
                value: [id],
            },
        ],
    })
    return parseSupplier(res.data.data.supplier[0])
}

export async function generateSupplierExtract(id: number): Promise<void> {
    await get(`supplier/extract/${id}`, {
        useRecaptcha: true,
    })
}

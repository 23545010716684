import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from 'react'
import { AccountInfo, useAccount } from './account_context'
import { CoinInfo, useCoin } from './coin_context'
import { useLoading } from './loading_context'
import { useSnack } from './snack_context'
import { createSpent, createSpentType, listSpentTypes } from '../services/spent'
import { useNavigate } from 'react-router-dom'
import { CashierInfo } from './cashier_context'
import { BranchInfo } from './branch_context'
import { SystemBranchEnum, useConfigs } from './configs_context'
import { PaymentMethodInfo } from './payment_method_context'
import { CashierClosedBuyError } from '../services/buy'
import {
    CreateServiceInterface,
    createService,
    createServiceType,
    getServiceById,
    listServiceTypes,
    updateService,
} from '../services/service'
import { getDateInIsoFormat } from '../utils/maks'
import { ClientInfo, anonymousClient } from './client_context'
import { ServiceNoteDialog } from '../components/ServiceNoteDialog'

export interface ServiceTypeInfo {
    id: number
    name: string
}

export enum ServiceStatusEnum {
    Pending = 0,
    Closed = 1,
    Canceled = 2,
}

export const ServiceStatusLabel: Record<number, string> = {
    0: 'Pendiente',
    1: 'Cerrada',
    2: 'Cancelada',
}

export interface ServiceInfo {
    id?: number
    serviceType: ServiceTypeInfo | null
    coin: CoinInfo | null
    cashier: Partial<CashierInfo> | null
    account: Partial<AccountInfo> | null
    value: number
    branch: BranchInfo | null
    finalDate: Date | null
    serviceStatus: number
    description?: string
    client: ClientInfo | null
    date?: string
    hour?: string
    isSummerTime?: boolean
}

export interface CreateServiceInfo extends ServiceInfo {
    newServiceTypeName: string
    isEditing: boolean
}

export interface ServiceError {
    serviceType: string
    coin: string
    description: string
    value: string
    newServiceTypeName: string
    finalDate: string
    branch: string
}

export const createServiceInfoDefault: CreateServiceInfo = {
    serviceType: null,
    coin: null,
    cashier: null,
    account: null,
    description: '',
    value: 0,
    isEditing: false,
    newServiceTypeName: '',
    finalDate: null,
    serviceStatus: ServiceStatusEnum.Pending,
    client: anonymousClient,
    branch: null,
}

export const serviceErrorDefault: ServiceError = {
    serviceType: '',
    coin: '',
    description: '',
    value: '',
    newServiceTypeName: '',
    finalDate: '',
    branch: '',
}

export interface ServiceContextInterface {
    createServiceInfo: CreateServiceInfo
    setCreateServiceInfo: Dispatch<SetStateAction<CreateServiceInfo>>
    serviceError: ServiceError
    setServiceError: Dispatch<SetStateAction<ServiceError>>
    serviceTypes: ServiceTypeInfo[]
    handleLoadServiceTypes(): Promise<void>
    handleCreateServiceType(): Promise<void>
    validate(): boolean
    handleCreateNewService(): Promise<void>
    startNewService(): void
    editService(serviceId: number): Promise<void>
}

const ServiceContext = createContext<ServiceContextInterface>({
    createServiceInfo: createServiceInfoDefault,
    setCreateServiceInfo: () => {},
    serviceError: serviceErrorDefault,
    setServiceError: () => {},
    serviceTypes: [],
    handleLoadServiceTypes: () => Promise.resolve(),
    handleCreateServiceType: () => Promise.resolve(),
    validate: () => false,
    handleCreateNewService: () => Promise.resolve(),
    startNewService: () => {},
    editService: () => Promise.resolve(),
})

export function useService() {
    return useContext(ServiceContext)
}

interface Props {
    children: ReactNode
}

export const ServiceContextProvider = ({ children }: Props) => {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [createServiceInfo, setCreateServiceInfo] =
        useState<CreateServiceInfo>(createServiceInfoDefault)

    const [serviceError, setServiceError] =
        useState<ServiceError>(serviceErrorDefault)
    const [serviceTypes, setServiceTypes] = useState<ServiceTypeInfo[]>([])
    const [printService, setPrintService] = useState<ServiceInfo | null>(null)
    const { accountInfo } = useAccount()
    const { configs } = useConfigs()
    const { coins } = useCoin()

    const startNewService = () => {
        setCreateServiceInfo({ ...createServiceInfoDefault })
        setServiceError({ ...serviceErrorDefault })
    }

    const editService = async (id: number) => {
        try {
            const service = await getServiceById(id, coins)

            setCreateServiceInfo(() => ({
                ...service,
                isEditing: true,
                newServiceTypeName: '',
            }))
            setServiceError({ ...serviceErrorDefault })
        } catch (error) {
            console.log(error)
            snack.error('Cliente não encontrado')
            throw new Error('Client not found')
        }
    }

    const handleLoadServiceTypes = async () => {
        if (serviceTypes.length) return

        loading.logicShow()
        try {
            const res = await listServiceTypes()
            setServiceTypes(res)
        } catch (error) {
            snack.connectionFail()
        }
        loading.logicHide()
    }

    const handleCreateServiceType = async () => {
        if (!createServiceInfo.newServiceTypeName) {
            setServiceError((prev) => ({
                ...prev,
                newServiceTypeName: 'Campo obrigatório',
            }))
            return
        }

        loading.show()
        try {
            const id = await createServiceType(
                createServiceInfo.newServiceTypeName
            )
            const res = await listServiceTypes()
            setServiceTypes(res)
            setCreateServiceInfo((prev) => ({
                ...prev,
                serviceType: res.find((r) => r.id === id) ?? null,
                newServiceTypeName: '',
            }))
            setServiceError((prev) => ({
                ...prev,
                spentType: '',
                newServiceTypeName: '',
            }))
        } catch (error) {
            snack.connectionFail()
        }
        loading.hide()
    }

    const validate = (): boolean => {
        let isOk = true
        const newServiceError = { ...serviceErrorDefault }

        if (!createServiceInfo.serviceType) {
            newServiceError.serviceType = 'Campo obrigatório'
            isOk = false
        }
        if (!createServiceInfo.coin) {
            newServiceError.coin = 'Campo obrigatório'
            isOk = false
        }
        if (!createServiceInfo.value) {
            newServiceError.value = 'Campo obrigatório'
            isOk = false
        }
        if (!createServiceInfo.finalDate) {
            newServiceError.finalDate = 'Campo obrigatório'
            isOk = false
        }
        if (
            accountInfo?.isMaster &&
            configs.systemBranch === SystemBranchEnum.Branch &&
            !createServiceInfo.branch
        ) {
            newServiceError.branch = 'Campo obrigatório'
            isOk = false
        }

        setServiceError({ ...newServiceError })
        return isOk
    }

    const handleCreateNewService = async () => {
        loading.show()
        try {
            let id = 0
            const body: CreateServiceInterface = {
                id_tipo_servico: createServiceInfo.serviceType?.id ?? 0,
                id_moeda: createServiceInfo.coin?.id ?? 0,
                valor: createServiceInfo.value,
                data_final: getDateInIsoFormat(
                    createServiceInfo.finalDate as Date
                ),
                flag_estado: createServiceInfo.serviceStatus,
                descricao: createServiceInfo.description
                    ? createServiceInfo.description
                    : undefined,
                id_cliente: createServiceInfo.client?.id
                    ? createServiceInfo.client.id
                    : null,
                id_filial_master: createServiceInfo.branch?.id,
            }
            if (!createServiceInfo.isEditing) id = await createService(body)
            else
                id = await updateService({
                    ...body,
                    id_servico: createServiceInfo.id as number,
                })
            snack.success(
                `¡Servicio ${
                    !createServiceInfo.isEditing ? 'creado' : 'actualizado'
                } con éxito!`
            )
            const res = await getServiceById(id, coins)
            setPrintService(res)
            navigate('/system/service')
            setCreateServiceInfo({ ...createServiceInfoDefault })
        } catch (error) {
            if (error instanceof CashierClosedBuyError)
                snack.error('¡La caja está cerrada!')
            else snack.connectionFail()
        }
        loading.hide()
    }

    return (
        <ServiceContext.Provider
            value={{
                createServiceInfo,
                setCreateServiceInfo,
                serviceError,
                setServiceError,
                serviceTypes,
                handleLoadServiceTypes,
                handleCreateServiceType,
                validate,
                handleCreateNewService,
                startNewService,
                editService,
            }}
        >
            {printService && (
                <ServiceNoteDialog
                    services={[printService]}
                    handleClose={() => setPrintService(null)}
                />
            )}
            {children}
        </ServiceContext.Provider>
    )
}

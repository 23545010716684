import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
    craeteBranch,
    getBranchById,
    listBranches,
    updateBranch,
} from '../services/branch'
import { useAccount } from './account_context'
import { useLoading } from './loading_context'
import { useSnack } from './snack_context'
import {
    ContactInfo,
    ContactTypeEnum,
    SystemBranchEnum,
    contactInfoDefault,
    useConfigs,
} from './configs_context'
import { getDateInIsoFormat, validateEmail, validateRuc } from '../utils/maks'

export interface BranchInfo {
    id: number
    name: string
    description: string | null
    isActive: boolean
    companyDocument: string | null
    companyRegime: string
    companyEmissionDepartmentId: string
    companyEmissionDepartmentDescription: string
    companyEmissionCityId: string
    companyEmissionCityDescription: string
    companyEconomicActivityId: string
    companyEconomicActivityDescription: string
    companyEmissionHouseNumber: string
    companyEmissionDistrictId: string
    companyEmissionDistrictDescription: string
    useElectronicNote: number
    publicCertificateEletronicNote: string
    privateKeyEletronicNote: string
    secretCodeEletronicNote: string
    dateEletronicNote: Date | null
    contacts: ContactInfo[]
}

export interface CraeteBranchInfo extends Omit<BranchInfo, 'id'> {
    id?: number
    isEditing: boolean
    contacts: ContactInfo[]
}

export interface BranchError {
    name: string
    description: string
    companyDocument: string
    contacts: string[]
}

const branchInfoDefault: CraeteBranchInfo = {
    name: '',
    description: '',
    isActive: true,
    isEditing: false,
    companyDocument: '',
    companyRegime: '',
    companyEmissionDepartmentId: '',
    companyEmissionDepartmentDescription: '',
    companyEmissionCityId: '',
    companyEmissionCityDescription: '',
    companyEconomicActivityId: '',
    companyEconomicActivityDescription: '',
    companyEmissionHouseNumber: '',
    companyEmissionDistrictId: '',
    companyEmissionDistrictDescription: '',
    useElectronicNote: 0,
    publicCertificateEletronicNote: '',
    privateKeyEletronicNote: '',
    secretCodeEletronicNote: '',
    dateEletronicNote: null,
    contacts: [{ ...contactInfoDefault }],
}

const branchErrorDefault: BranchError = {
    name: '',
    description: '',
    companyDocument: '',
    contacts: [],
}

export interface BranchContextInterface {
    branches: BranchInfo[]
    createBranchInfo: CraeteBranchInfo
    setCreateBranchInfo: Dispatch<SetStateAction<CraeteBranchInfo>>
    branchError: BranchError
    setBranchError: Dispatch<SetStateAction<BranchError>>
    validate(): boolean
    handleCreateNewBranch(): Promise<void>
    startNewBranch(): void
    editBranch(id: number): Promise<void>
    getBranches(): Promise<void>
}

const branchContextDefault: BranchContextInterface = {
    branches: [],
    createBranchInfo: branchInfoDefault,
    setCreateBranchInfo: () => {},
    branchError: branchErrorDefault,
    setBranchError: () => {},
    validate: () => false,
    handleCreateNewBranch: () => Promise.resolve(),
    startNewBranch: () => {},
    editBranch: () => Promise.resolve(),
    getBranches: () => Promise.resolve(),
}

const BranchContext =
    createContext<BranchContextInterface>(branchContextDefault)

export function useBranch() {
    return useContext(BranchContext)
}

interface Props {
    children: ReactNode
}

export function BranchContextProvider({ children }: Props) {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [branches, setBranches] = useState<BranchInfo[]>([])
    const [createBranchInfo, setCreateBranchInfo] =
        useState<CraeteBranchInfo>(branchInfoDefault)
    const [branchError, setBranchError] =
        useState<BranchError>(branchErrorDefault)
    const { accountInfo } = useAccount()
    const { configs } = useConfigs()

    useEffect(() => {
        if (!accountInfo || !configs.id || branches.length) return
        if (
            (!accountInfo?.branch || configs.showBranchStock) &&
            configs.systemBranch === SystemBranchEnum.Branch
        ) {
            getBranches()
        }
    }, [accountInfo, configs])

    const getBranches = async () => {
        loading.show()
        try {
            const res = await listBranches()
            setBranches(res)
        } catch (error) {
            snack.error('Erro ao carregar filiais')
        }
        loading.hide()
    }

    const validate = (): boolean => {
        let isOk = true
        const newBranchError = { ...branchErrorDefault }

        const filteredContacts = createBranchInfo.contacts.filter(
            (c) => c.value && c.contactType.id > 0
        )

        if (!createBranchInfo.name) {
            newBranchError.name = 'Campo obrigatório'
            isOk = false
        }

        if (
            createBranchInfo.companyDocument &&
            !validateRuc(createBranchInfo.companyDocument)
        ) {
            newBranchError.companyDocument = 'RUC inválido'
            isOk = false
        }

        if (
            !filteredContacts.find(
                (c) => c.contactType.id === ContactTypeEnum.Address
            )
        ) {
            isOk = false
            snack.error('Pelo menos um endereço deve ser inserido')
        } else if (
            !filteredContacts.find(
                (c) => c.contactType.id === ContactTypeEnum.Phone
            )
        ) {
            isOk = false
            snack.error('Pelo menos um telefone deve ser inserido')
        } else if (
            !filteredContacts.find(
                (c) => c.contactType.id === ContactTypeEnum.Email
            )
        ) {
            isOk = false
            snack.error('Pelo menos um email deve ser inserido')
        }

        for (let i = 0; i < filteredContacts.length; i++) {
            if (
                filteredContacts[i].contactType.id === ContactTypeEnum.Email &&
                !validateEmail(filteredContacts[i].value)
            ) {
                isOk = false
                newBranchError.contacts[i] = 'Email inválido'
            }
            if (
                filteredContacts[i].contactType.id === ContactTypeEnum.Phone &&
                filteredContacts[i].value.length > 15
            ) {
                isOk = false
                newBranchError.contacts[i] =
                    'O telefone não pode ter mais de 15 caracteres'
            }
        }

        setBranchError({ ...newBranchError })
        return isOk
    }

    const handleCreateNewBranch = async () => {
        loading.show()
        try {
            if (!createBranchInfo.isEditing)
                await craeteBranch({
                    nome: createBranchInfo.name,
                    descricao: createBranchInfo.description || null,
                    flag_ativo: Number(createBranchInfo.isActive),
                    documento_empresa: createBranchInfo.companyDocument || null,
                    regime_empresa: createBranchInfo.companyRegime || null,
                    cod_departamento_emissao_empresa:
                        createBranchInfo.companyEmissionDepartmentId || null,
                    desc_departamento_emissao_empresa:
                        createBranchInfo.companyEmissionDepartmentDescription ||
                        null,
                    cod_cidade_emissao_empresa:
                        createBranchInfo.companyEmissionCityId || null,
                    desc_cidade_emissao_empresa:
                        createBranchInfo.companyEmissionCityDescription || null,
                    cod_atividade_economica_empresa:
                        createBranchInfo.companyEconomicActivityId || null,
                    desc_atividade_economica_empresa:
                        createBranchInfo.companyEconomicActivityDescription ||
                        null,
                    num_casa_emissao_empresa:
                        createBranchInfo.companyEmissionHouseNumber || null,
                    cod_distrito_emissao_empresa:
                        createBranchInfo.companyEmissionDistrictId || null,
                    desc_distrito_emissao_empresa:
                        createBranchInfo.companyEmissionDistrictDescription ||
                        null,
                    flag_fatura_eletronica: createBranchInfo.useElectronicNote,
                    certificado_publico_fatura:
                        createBranchInfo.publicCertificateEletronicNote
                            ? createBranchInfo.publicCertificateEletronicNote
                            : undefined,
                    chave_privada_fatura:
                        createBranchInfo.privateKeyEletronicNote
                            ? createBranchInfo.privateKeyEletronicNote
                            : undefined,
                    codigo_secreto_fatura:
                        createBranchInfo.secretCodeEletronicNote
                            ? createBranchInfo.secretCodeEletronicNote
                            : undefined,
                    data_fatura: createBranchInfo.dateEletronicNote
                        ? getDateInIsoFormat(
                              createBranchInfo.dateEletronicNote,
                              true
                          )
                        : undefined,
                    contatos: createBranchInfo.contacts
                        .filter((c) => c.value && c.contactType.id > 0)
                        .map((c) => ({
                            valor: c.value,
                            id_tipo_contato: c.contactType.id,
                        })),
                })
            else
                await updateBranch({
                    id_filial_existente: createBranchInfo.id ?? 0,
                    nome: createBranchInfo.name,
                    descricao: createBranchInfo.description || null,
                    flag_ativo: Number(createBranchInfo.isActive),
                    documento_empresa: createBranchInfo.companyDocument || null,
                    regime_empresa: createBranchInfo.companyRegime || null,
                    cod_departamento_emissao_empresa:
                        createBranchInfo.companyEmissionDepartmentId || null,
                    desc_departamento_emissao_empresa:
                        createBranchInfo.companyEmissionDepartmentDescription ||
                        null,
                    cod_cidade_emissao_empresa:
                        createBranchInfo.companyEmissionCityId || null,
                    desc_cidade_emissao_empresa:
                        createBranchInfo.companyEmissionCityDescription || null,
                    cod_atividade_economica_empresa:
                        createBranchInfo.companyEconomicActivityId || null,
                    desc_atividade_economica_empresa:
                        createBranchInfo.companyEconomicActivityDescription ||
                        null,
                    num_casa_emissao_empresa:
                        createBranchInfo.companyEmissionHouseNumber || null,
                    cod_distrito_emissao_empresa:
                        createBranchInfo.companyEmissionDistrictId || null,
                    desc_distrito_emissao_empresa:
                        createBranchInfo.companyEmissionDistrictDescription ||
                        null,
                    flag_fatura_eletronica: createBranchInfo.useElectronicNote,
                    certificado_publico_fatura:
                        createBranchInfo.publicCertificateEletronicNote
                            ? createBranchInfo.publicCertificateEletronicNote
                            : undefined,
                    chave_privada_fatura:
                        createBranchInfo.privateKeyEletronicNote
                            ? createBranchInfo.privateKeyEletronicNote
                            : undefined,
                    codigo_secreto_fatura:
                        createBranchInfo.secretCodeEletronicNote
                            ? createBranchInfo.secretCodeEletronicNote
                            : undefined,
                    data_fatura: createBranchInfo.dateEletronicNote
                        ? getDateInIsoFormat(
                              createBranchInfo.dateEletronicNote,
                              true
                          )
                        : undefined,
                    contatos: createBranchInfo.contacts
                        .filter((c) => c.value && c.contactType.id > 0)
                        .map((c) => ({
                            valor: c.value,
                            id_tipo_contato: c.contactType.id,
                        })),
                })
            getBranches()
            snack.success(
                `Filial ${
                    !createBranchInfo.isEditing ? 'criada' : 'atualizada'
                } com sucesso!`
            )
            navigate('/system/branch')
            setCreateBranchInfo({ ...branchInfoDefault })
            setBranchError({ ...branchErrorDefault })
        } catch (error) {
            snack.connectionFail()
        }
        loading.hide()
    }

    const startNewBranch = () => {
        setCreateBranchInfo({ ...branchInfoDefault })
        setBranchError({ ...branchErrorDefault })
    }

    const editBranch = async (id: number) => {
        try {
            const branch = await getBranchById(id)

            setCreateBranchInfo((prev) => ({
                ...branch,
                isEditing: true,
            }))
            setBranchError({ ...branchErrorDefault })
        } catch (error) {
            console.log(error)
            snack.error('Filial não encontrada')
            throw new Error('Branch not found')
        }
    }

    return (
        <BranchContext.Provider
            value={{
                branches,
                createBranchInfo,
                setCreateBranchInfo,
                branchError,
                setBranchError,
                validate,
                handleCreateNewBranch,
                startNewBranch,
                editBranch,
                getBranches,
            }}
        >
            {children}
        </BranchContext.Provider>
    )
}

import {
    Dispatch,
    SetStateAction,
    ReactNode,
    useState,
    createContext,
    useContext,
    useEffect,
} from 'react'
import { useSnack } from './snack_context'
import { useLoading } from './loading_context'
import { createCoin, listCoins, updateCoin } from '../services/coin'
import { useNavigate } from 'react-router-dom'
import { useAccount } from './account_context'

export enum CoinType {
    Main = 1,
    Normal = 2,
    Other = 3,
}

export enum CoinExchangeType {
    Normal = 0,
    Reverse = 1,
}

export interface CoinInfo {
    id?: number
    name: string
    iso: string
    symbol: string
    pathImage: string
    type: CoinType
    buyExchange: number
    saleExchange: number
    flagReverseExchange: number
    hideDecimal: boolean
}

export interface CreateCoinInfo extends CoinInfo {
    step: number
    isEditing: boolean
}

export interface CoinErrorInfo {
    name: string
    iso: string
    symbol: string
    buyExchange: string
    saleExchange: string
}

const createCoinInfoDefault: CreateCoinInfo = {
    name: '',
    iso: '',
    symbol: '',
    pathImage: '',
    type: CoinType.Normal,
    buyExchange: 0,
    saleExchange: 0,
    step: 1,
    isEditing: false,
    flagReverseExchange: 0,
    hideDecimal: false,
}

const coinErrorDefault: CoinErrorInfo = {
    name: '',
    iso: '',
    symbol: '',
    buyExchange: '',
    saleExchange: '',
}

export interface CoinContextInterface {
    coins: CoinInfo[]
    normalCoins: CoinInfo[]
    mainCoin?: CoinInfo
    createCoinInfo: CreateCoinInfo
    setCreateCoinInfo: Dispatch<SetStateAction<CreateCoinInfo>>
    coinErrorInfo: CoinErrorInfo
    setCoinErrorInfo: Dispatch<SetStateAction<CoinErrorInfo>>
    initCreateNewCoin(): void
    editCoin(id: number): Promise<void>
    validate(): boolean
    handleCreateNewCoin(): Promise<void>
    getCoins(): Promise<void>
}

const coinContext = createContext<CoinContextInterface>({
    coins: [],
    normalCoins: [],
    createCoinInfo: createCoinInfoDefault,
    setCreateCoinInfo: () => {},
    coinErrorInfo: coinErrorDefault,
    setCoinErrorInfo: () => {},
    initCreateNewCoin: () => {},
    editCoin: async () => {},
    validate: () => false,
    handleCreateNewCoin: async () => {},
    getCoins: async () => {},
})

export function useCoin() {
    return useContext(coinContext)
}

interface Props {
    children: ReactNode
}

export const CoinContextProvider = ({ children }: Props) => {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [coins, setCoins] = useState<CoinInfo[]>([])

    const [createCoinInfo, setCreateCoinInfo] = useState<CreateCoinInfo>(
        createCoinInfoDefault
    )
    const [coinErrorInfo, setCoinErrorInfo] =
        useState<CoinErrorInfo>(coinErrorDefault)
    const { accountInfo } = useAccount()

    useEffect(() => {
        if (!accountInfo) return
        if (
            accountInfo?.isMaster ||
            accountInfo.rules.findIndex(
                (r) =>
                    r.group.toLowerCase() === 'moedas' &&
                    r.name.toLowerCase() === 'listar'
            ) !== -1
        ) {
            getCoins()
            /* setInterval(
                () => {
                    getCoins()
                },
                1000 * 60 * 5
            ) */
        }
    }, [accountInfo])

    const getCoins = async () => {
        loading.show()
        try {
            const res = await listCoins()
            setCoins(res)
        } catch (error) {
            snack.error('Erro ao carregar moedas')
        }
        loading.hide()
    }

    const initCreateNewCoin = () => {
        setCreateCoinInfo({ ...createCoinInfoDefault })
    }

    const editCoin = async (id: number) => {
        const coin = coins.find((c) => c.id === id)

        if (!coin) throw new Error('Coin not found')
        setCreateCoinInfo({ ...coin, isEditing: true, step: 1 })
    }

    const validate = (): boolean => {
        let isOk = true

        if (!createCoinInfo.name) {
            setCoinErrorInfo((prev) => ({
                ...prev,
                name: 'Campo obrigatório',
            }))
            isOk = false
        }
        if (!createCoinInfo.iso) {
            setCoinErrorInfo((prev) => ({
                ...prev,
                iso: 'Campo obrigatório',
            }))
            isOk = false
        }
        if (!createCoinInfo.symbol) {
            setCoinErrorInfo((prev) => ({
                ...prev,
                symbol: 'Campo obrigatório',
            }))
            isOk = false
        }
        if (
            !createCoinInfo.buyExchange &&
            createCoinInfo.type === CoinType.Normal
        ) {
            setCoinErrorInfo((prev) => ({
                ...prev,
                buyExchange: 'Campo obrigatório',
            }))
            isOk = false
        }
        if (
            !createCoinInfo.saleExchange &&
            createCoinInfo.type === CoinType.Normal
        ) {
            setCoinErrorInfo((prev) => ({
                ...prev,
                saleExchange: 'Campo obrigatório',
            }))
            isOk = false
        }
        if (!createCoinInfo.pathImage) {
            snack.error('Selecione uma bandeira para a moeda')
            isOk = false
        }

        return isOk
    }

    const handleCreateNewCoin = async () => {
        loading.show()
        try {
            if (!createCoinInfo.isEditing)
                await createCoin({
                    nome: createCoinInfo.name,
                    iso: createCoinInfo.iso,
                    simbolo: createCoinInfo.symbol,
                    cotacao_compra: createCoinInfo.buyExchange,
                    cotacao_venda: createCoinInfo.saleExchange,
                    path_imagem: createCoinInfo.pathImage,
                    flag_cambio_reverso: createCoinInfo.flagReverseExchange,
                    flag_esconder_decimal: Number(createCoinInfo.hideDecimal),
                })
            else
                await updateCoin({
                    id_moeda: createCoinInfo.id as number,
                    nome: createCoinInfo.name,
                    iso: createCoinInfo.iso,
                    simbolo: createCoinInfo.symbol,
                    cotacao_compra: createCoinInfo.buyExchange,
                    cotacao_venda: createCoinInfo.saleExchange,
                    path_imagem: createCoinInfo.pathImage,
                    flag_cambio_reverso: createCoinInfo.flagReverseExchange,
                    flag_esconder_decimal: Number(createCoinInfo.hideDecimal),
                })
            await getCoins()
            snack.success(
                `Moeda ${
                    !createCoinInfo.isEditing ? 'criada' : 'atualizada'
                } com sucesso!`
            )
            navigate('/system/coin')
        } catch (error) {
            snack.error('Erro ao criar moeda')
        }
        loading.hide()
    }

    return (
        <coinContext.Provider
            value={{
                coins,
                normalCoins: coins.filter((c) => c.type === CoinType.Normal),
                mainCoin: coins.find((c) => c.type === CoinType.Main),
                createCoinInfo,
                setCreateCoinInfo,
                coinErrorInfo,
                setCoinErrorInfo,
                initCreateNewCoin,
                editCoin,
                validate,
                handleCreateNewCoin,
                getCoins,
            }}
        >
            {children}
        </coinContext.Provider>
    )
}

import { VehicleInfo } from '../pages/Vehicle/Home'
import { get, post, put } from './generic'

export interface VehicleApiData {
    id_veiculo: number
    tipo_transporte: number
    modalidade_transporte: number
    marca: string
    tipo_identificacao: number
    numero_documento: string
    informacoes_adicionais?: string | null
}

export interface CreateVehicleInterface {
    tipo_transporte: number
    modalidade_transporte: number
    marca: string
    tipo_identificacao: number
    numero_documento: string
    informacoes_adicionais?: string | null
}

export interface UpdateVehicleInterface
    extends Partial<CreateVehicleInterface> {
    id_veiculo: number
}

export function parseVehicle(data: VehicleApiData): VehicleInfo {
    return {
        id: data.id_veiculo,
        brand: data.marca,
        document: data.numero_documento,
        idType: data.tipo_identificacao,
        modality: data.modalidade_transporte,
        type: data.tipo_transporte,
        additionalInfo: data.informacoes_adicionais,
    }
}

export async function listVehicles(): Promise<VehicleInfo[]> {
    const res = await get('vehicle')
    return res.data.data.map(parseVehicle)
}

export async function findVehicleById(id: number): Promise<VehicleInfo> {
    const res = await get(`vehicle/${id}`)
    return parseVehicle(res.data.data)
}

export async function createVehicle(
    data: CreateVehicleInterface
): Promise<void> {
    await post('vehicle', data, {
        useRecaptcha: true,
    })
}

export async function updateVehicle(
    data: UpdateVehicleInterface
): Promise<void> {
    await put('vehicle', data, {
        useRecaptcha: true,
    })
}

import { CoinInfo } from '../context/coin_context'
import { get, post, put } from './generic'

export interface CreateCoinInterface {
    nome: string
    cotacao_compra: number
    cotacao_venda: number
    iso: string
    path_imagem: string
    simbolo: string
    flag_cambio_reverso: number
    flag_esconder_decimal: number
}

interface CoinApiData {
    id_moeda: number
    nome: string
    cotacao_compra: string
    cotacao_venda: string
    flag_tipo: number
    flag_ativo: number
    iso: string
    path_imagem: string
    simbolo: string
    flag_cambio_reverso: number
    flag_esconder_decimal: number
}

export interface UpdateCoinInterface extends Partial<CreateCoinInterface> {
    id_moeda: number
}

export function parseCoin(data: CoinApiData): CoinInfo {
    return {
        id: data.id_moeda,
        name: data.nome,
        iso: data.iso,
        symbol: data.simbolo,
        type: data.flag_tipo,
        pathImage: data.path_imagem,
        buyExchange: Number(data.cotacao_compra),
        saleExchange: Number(data.cotacao_venda),
        flagReverseExchange: data.flag_cambio_reverso,
        hideDecimal: Boolean(data.flag_esconder_decimal),
    }
}

export async function createCoin(data: CreateCoinInterface): Promise<void> {
    await post('currency', data, {
        useRecaptcha: true,
    })
}

export async function listCoins(): Promise<CoinInfo[]> {
    const res = await get('currency')
    return res.data.data.map((data: CoinApiData) => parseCoin(data))
}

export async function updateCoin(data: UpdateCoinInterface): Promise<void> {
    await put('currency', data, {
        useRecaptcha: true,
    })
}

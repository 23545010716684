import { BranchInfo } from '../context/branch_context'
import { ContactApiData, parseConctact } from './configs'
import { get, post, put } from './generic'
import {
    ConfigsInfo,
    ContactInfo,
    ContactTypeInfo,
    contactInfoDefault,
} from '../context/configs_context'

export interface BranchApiData {
    id_filial: number
    nome: string
    descricao: string | null
    flag_ativo: number
    documento_empresa: string | null
    regime_empresa: string | null
    cod_departamento_emissao_empresa: string | null
    desc_departamento_emissao_empresa: string | null
    cod_cidade_emissao_empresa: string | null
    desc_cidade_emissao_empresa: string | null
    cod_atividade_economica_empresa: string | null
    desc_atividade_economica_empresa: string | null
    cod_distrito_emissao_empresa: string | null
    desc_distrito_emissao_empresa: string | null
    num_casa_emissao_empresa: string | null
    flag_fatura_eletronica: number
    certificado_publico_fatura: string | null
    chave_privada_fatura: string | null
    codigo_secreto_fatura: string | null
    data_fatura: Date | string | null
    contatos: ContactApiData[]
}

interface CreateBranchInterface {
    nome: string
    descricao: string | null
    flag_ativo: number
    documento_empresa: string | null
    regime_empresa: string | null
    cod_departamento_emissao_empresa: string | null
    desc_departamento_emissao_empresa: string | null
    cod_cidade_emissao_empresa: string | null
    desc_cidade_emissao_empresa: string | null
    cod_atividade_economica_empresa: string | null
    desc_atividade_economica_empresa: string | null
    cod_distrito_emissao_empresa: string | null
    desc_distrito_emissao_empresa: string | null
    num_casa_emissao_empresa: string | null
    flag_fatura_eletronica: number
    certificado_publico_fatura?: string | null
    chave_privada_fatura?: string | null
    codigo_secreto_fatura?: string | null
    data_fatura?: Date | string | null
    contatos: {
        valor: string
        id_tipo_contato: number
    }[]
}

interface UpdateBranchInterface extends Partial<CreateBranchInterface> {
    id_filial_existente: number
}

export function parseBranch(data: BranchApiData): BranchInfo {
    return {
        id: data.id_filial,
        name: data.nome,
        description: data.descricao,
        isActive: Boolean(data.flag_ativo),
        companyDocument: data.documento_empresa,
        companyRegime: data.regime_empresa ?? '',
        companyEmissionDepartmentId:
            data.cod_departamento_emissao_empresa ?? '',
        companyEmissionDepartmentDescription:
            data.desc_departamento_emissao_empresa ?? '',
        companyEmissionCityId: data.cod_cidade_emissao_empresa ?? '',
        companyEmissionCityDescription: data.desc_cidade_emissao_empresa ?? '',
        companyEconomicActivityId: data.cod_atividade_economica_empresa ?? '',
        companyEconomicActivityDescription:
            data.desc_atividade_economica_empresa ?? '',
        companyEmissionHouseNumber: data.num_casa_emissao_empresa ?? '',
        companyEmissionDistrictId: data.cod_distrito_emissao_empresa ?? '',
        companyEmissionDistrictDescription:
            data.desc_distrito_emissao_empresa ?? '',
        useElectronicNote: data.flag_fatura_eletronica,
        publicCertificateEletronicNote: data.certificado_publico_fatura ?? '',
        privateKeyEletronicNote: data.chave_privada_fatura ?? '',
        secretCodeEletronicNote: data.codigo_secreto_fatura ?? '',
        dateEletronicNote: data.data_fatura ? new Date(data.data_fatura) : null,
        contacts: data.contatos?.length
            ? data.contatos.map(parseConctact)
            : [{ ...contactInfoDefault }],
    }
}

export async function listBranches(): Promise<BranchInfo[]> {
    const res = await get('branch')

    return res.data.data.map(parseBranch)
}

export async function getBranchById(id: number): Promise<BranchInfo> {
    const res = await get(`branch/${id}`)

    return parseBranch(res.data.data)
}

export async function craeteBranch(data: CreateBranchInterface): Promise<void> {
    await post('branch', data, {
        useRecaptcha: true,
    })
}

export async function updateBranch(data: UpdateBranchInterface): Promise<void> {
    await put('branch', data, {
        useRecaptcha: true,
    })
}

import {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
    ReactNode,
} from 'react'
import { PasswordModal } from '../components/PasswordModal'

interface ShowPasswordOptions {
    confirmPassword: boolean
    returnCb(password: string): void
}

const showPasswordOptionsDefault: ShowPasswordOptions = {
    confirmPassword: false,
    returnCb: () => {},
}

interface PasswordContextInterface {
    show(options: ShowPasswordOptions): void
    showError(err: string): void
    hide(): void
}

const passwordContextDefault: PasswordContextInterface = {
    show: () => {},
    showError: () => {},
    hide: () => {},
}

const passwordContext = createContext<PasswordContextInterface>(
    passwordContextDefault
)

export function usePassword() {
    return useContext(passwordContext)
}

interface Props {
    children: ReactNode
}

export const PasswordContextProvider = ({ children }: Props) => {
    const [showModal, setShowModal] = useState(false)
    const [options, setOptions] = useState<ShowPasswordOptions>(
        showPasswordOptionsDefault
    )
    const [err, setErr] = useState('')

    const show = (options: ShowPasswordOptions) => {
        setShowModal(true)
        setOptions(options)
    }

    const showError = (err: string) => {
        setErr(err)
    }

    const hide = () => {
        setShowModal(false)
    }

    return (
        <passwordContext.Provider value={{ show, showError, hide }}>
            <PasswordModal
                open={showModal}
                handleClose={setShowModal}
                confirmPassword={options.confirmPassword}
                returnPassword={options.returnCb}
                err={err}
                setErr={setErr}
            />
            {children}
        </passwordContext.Provider>
    )
}

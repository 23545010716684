import {
    ConfigsInfo,
    ContactInfo,
    ContactTypeInfo,
    contactInfoDefault,
} from '../context/configs_context'
import { get, post, put } from './generic'

export interface ContactTypeApiData {
    id_tipo_contato: number
    nome: string
}

export interface ContactApiData {
    id_contato: number
    valor: string
    id_tipo_contato: number
    id_configs: number
    tipoContato: ContactTypeApiData
}

interface ConfigsApiData {
    id_configs: number
    fuso_horario: number
    fuso_horario_pais: string
    flag_horario_verao: number
    metodo_venda: number
    nome_empresa: string | null
    logo_empresa: string | null
    slogan_empresa: string | null
    documento_empresa: string | null
    digito_inicial_balanca: number
    qnt_digitos_cod_barra_balanca: number
    qnt_digitos_balanca: number
    digito_final_balanca: number
    tipo_balanca: number
    flag_sistema_filial: number
    flag_usar_senha_mestre: number
    regime_empresa: string
    cod_departamento_emissao_empresa: string
    desc_departamento_emissao_empresa: string
    cod_cidade_emissao_empresa: string
    desc_cidade_emissao_empresa: string
    cod_atividade_economica_empresa: string
    desc_atividade_economica_empresa: string
    num_casa_emissao_empresa: string
    cod_distrito_emissao_empresa: string
    desc_distrito_emissao_empresa: string
    flag_fatura_eletronica: number
    certificado_publico_fatura: string | null
    chave_privada_fatura: string | null
    codigo_secreto_fatura: string | null
    data_fatura: string | null
    flag_metodo_impressao: number
    tamanho_fonte_ticket: number
    flag_tipo_buscador: number
    flag_impressao_venda: number
    flag_impressao_venda_pendente: number
    flag_fatura_apos_venda: number
    flag_fatura_apos_finalizar_venda: number
    flag_exibir_contado: number
    posicoes_fatura_impressa: string | null
    printPosition: string | null
    quantidade_notas_fatura: number
    flag_imprimir_meia_folha: number
    flag_exibir_codigo_fatura: number
    flag_exibir_estoque_filiais: number
    flag_exibir_codigo: number
    flag_esconder_total_letras: number
    flag_tela_pagamento: number
    flag_esconder_pagare: number
    contatos: ContactApiData[]
}

interface UpdateConfigsInterface {
    fuso_horario: number
    fuso_horario_pais: string
    flag_horario_verao: number
    metodo_venda: number
    senha_mestre?: string | null
    nome_empresa: string | null
    logo_empresa: string | null
    slogan_empresa: string | null
    documento_empresa: string | null
    digito_inicial_balanca: number
    qnt_digitos_cod_barra_balanca: number
    qnt_digitos_balanca: number
    digito_final_balanca: number
    tipo_balanca: number
    flag_usar_senha_mestre: number
    regime_empresa: string
    cod_departamento_emissao_empresa: string
    desc_departamento_emissao_empresa: string
    cod_cidade_emissao_empresa: string
    desc_cidade_emissao_empresa: string
    cod_atividade_economica_empresa: string
    desc_atividade_economica_empresa: string
    num_casa_emissao_empresa: string
    cod_distrito_emissao_empresa: string
    desc_distrito_emissao_empresa: string
    flag_fatura_eletronica: number
    certificado_publico_fatura: string | null
    chave_privada_fatura: string | null
    codigo_secreto_fatura: string | null
    data_fatura: string | null
    flag_metodo_impressao: number
    tamanho_fonte_ticket: number
    flag_tipo_buscador: number
    flag_impressao_venda: number
    flag_impressao_venda_pendente: number
    flag_fatura_apos_venda: number
    flag_fatura_apos_finalizar_venda: number
    flag_exibir_contado: number
    posicoes_fatura_impressa: string | null
    quantidade_notas_fatura: number
    flag_imprimir_meia_folha: number
    flag_exibir_codigo_fatura: number
    flag_exibir_estoque_filiais: number
    flag_exibir_codigo: number
    flag_esconder_total_letras: number
    flag_tela_pagamento: number
    flag_esconder_pagare: number
    contatos: {
        valor: string
        id_tipo_contato: number
    }[]
}

export function parseContactType(data: ContactTypeApiData): ContactTypeInfo {
    return {
        id: data.id_tipo_contato,
        name: data.nome,
    }
}

export function parseConctact(data: ContactApiData): ContactInfo {
    return {
        id: data.id_contato,
        value: data.valor,
        contactType: data.tipoContato
            ? parseContactType(data.tipoContato)
            : data.tipoContato,
    }
}

function parseConfigs(data: ConfigsApiData): ConfigsInfo {
    return {
        id: data.id_configs,
        timeZone: data.fuso_horario,
        timeZoneCountry: data.fuso_horario_pais,
        isSummerTime: Boolean(data.flag_horario_verao),
        masterPassword: '',
        saleMethod: data.metodo_venda,
        companyName: data.nome_empresa,
        companyLogo: data.logo_empresa
            ? {
                  content: data.logo_empresa,
                  file: new File([], data.logo_empresa),
                  isBase64: false,
              }
            : null,
        companySlogan: data.slogan_empresa,
        companyDocument: data.documento_empresa,
        balance: {
            initialDigit: data.digito_inicial_balanca,
            barcodeLength: data.qnt_digitos_cod_barra_balanca,
            balanceLength: data.qnt_digitos_balanca,
            finalDigit: data.digito_final_balanca,
            balanceType: data.tipo_balanca,
        },
        systemBranch: data.flag_sistema_filial,
        useMasterPassword: Boolean(data.flag_usar_senha_mestre),
        companyRegime: data.regime_empresa,
        companyEmissionDepartmentId: data.cod_departamento_emissao_empresa,
        companyEmissionDepartmentDescription:
            data.desc_departamento_emissao_empresa,
        companyEmissionCityId: data.cod_cidade_emissao_empresa,
        companyEmissionCityDescription: data.desc_cidade_emissao_empresa,
        companyEconomicActivityId: data.cod_atividade_economica_empresa,
        companyEconomicActivityDescription:
            data.desc_atividade_economica_empresa,
        companyEmissionHouseNumber: data.num_casa_emissao_empresa,
        companyEmissionDistrictId: data.cod_distrito_emissao_empresa,
        companyEmissionDistrictDescription: data.desc_distrito_emissao_empresa,
        useElectronicNote: data.flag_fatura_eletronica,
        publicCertificateEletronicNote: data.certificado_publico_fatura ?? '',
        privateKeyEletronicNote: data.chave_privada_fatura ?? '',
        secretCodeEletronicNote: data.codigo_secreto_fatura ?? '',
        dateEletronicNote: data.data_fatura ? new Date(data.data_fatura) : null,
        printMethod: data.flag_metodo_impressao,
        ticketFontSize: data.tamanho_fonte_ticket,
        searchType: data.flag_tipo_buscador,
        salePrintMethod: data.flag_impressao_venda,
        salePrintMethodPending: data.flag_impressao_venda_pendente,
        noteAfterSale: Boolean(data.flag_fatura_apos_venda),
        noteAfterFinalizeSale: Boolean(data.flag_fatura_apos_finalizar_venda),
        showOnlyNormalPaymentType: Boolean(data.flag_exibir_contado),
        printPosition: data.posicoes_fatura_impressa,
        notesQnt: data.quantidade_notas_fatura,
        printHalfPage: Boolean(data.flag_imprimir_meia_folha),
        showIdOnNote: Boolean(data.flag_exibir_codigo_fatura),
        showBranchStock: Boolean(data.flag_exibir_estoque_filiais),
        showId: data.flag_exibir_codigo,
        hideWrittenTotal: Boolean(data.flag_esconder_total_letras),
        paymentScreen: data.flag_tela_pagamento,
        hidePromissoryNote: Boolean(data.flag_esconder_pagare),
        contacts: data.contatos.length
            ? data.contatos.map(parseConctact)
            : [{ ...contactInfoDefault }],
    }
}

export async function getConfigs(): Promise<ConfigsInfo> {
    const res = await get('configs')
    return parseConfigs(res.data.data.configs)
}

export async function updateConfigs(
    data: UpdateConfigsInterface
): Promise<void> {
    await put('configs', data, {
        useRecaptcha: true,
    })
}

export async function listContactTypes(): Promise<ContactTypeInfo[]> {
    const res = await get('contact-type')
    return res.data.data.map(parseContactType)
}

export async function validateMasterPassword(senha: string): Promise<void> {
    await post('configs/validate-master-password', {
        senha: senha,
    })
}

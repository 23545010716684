import { Model } from '../context/product_context'
import { get, post } from './generic'

interface ModelApiData {
    idModelo: number
    nome: string
    idMarca: number
}

function ParseModel(data: ModelApiData): Model {
    return {
        id: data.idModelo,
        name: data.nome,
        markId: data.idMarca,
    }
}

export async function getModelsByMarkId(markId: number): Promise<Model[]> {
    const res = await get(`modelos/${markId}`)
    return res.data.data.map((data: ModelApiData) => ParseModel(data))
}

export async function getModelById(id: number): Promise<Model> {
    const res = await get(`modelo/${id}`)
    return ParseModel(res.data.data.model)
}

export async function createModel(
    name: string,
    markId: number
): Promise<Model> {
    const res = await post(
        'modelo',
        {
            idMarca: markId,
            nome: name,
        },
        {
            useRecaptcha: true,
        }
    )
    return ParseModel(res.data.data.model)
}

import {
    CashierExpectedAmountInfo,
    CashierHistoric,
    CashierInfo,
    GeneralCashierExpectedAmountInfo,
} from '../context/cashier_context'
import { CoinInfo } from '../context/coin_context'
import { PaymentMethodInfo } from '../context/payment_method_context'
import { ListReturn } from '../utils/maks'
import { BranchApiData, parseBranch } from './branch'
import { parseCoin } from './coin'
import { get, post, put } from './generic'
import { parsePaymentMethod } from './payment'

export class InvalidCashierError extends Error {
    constructor() {
        super()
    }
}

export interface CashierApiData {
    id_caixa: number
    nome: string
    hash: string
    flag_ativo: number
    filial: BranchApiData | null
}

interface CashierHistoricApiData {
    id_caixa_historico: number
    id_caixa: number
    status: number
    data_abertura: string
    hora_abertura: string
    data_fechamento: string
    hora_fechamento: string
    flag_horario_verao: number
    flag_horario_verao_fechamento: number
    caixa: CashierApiData
    valoresCaixa: {
        id_valor_caixa: number
        id_moeda: number
        id_caixa_historico: number
        valor: number
        flag_tipo: number
    }[]
}

interface ManageCashierInterface {
    id: number
    values: {
        id_moeda: number
        valor: number
    }[]
}

interface GenerateCashierValueInterface {
    id_caixa: number
    id_moeda: number
    valor: number
    flag_tipo: number
}

interface CreateCashierInterface {
    nome: string
    hash: string
    flag_ativo: number
    id_filial_master?: number | null
}

interface UpdateCashierInterface {
    id_caixa: number
    nome?: string
    hash?: string
    flag_ativo?: number
    id_filial_master?: number | null
}

interface LoginCashierInterface {
    hash_caixa: string
}

interface ListCashierHistoricInterface {
    id: number
    page: number
    rows: number
}

export interface ListCashiersInterface {
    page: number
    rows: number
    search?: string
    id_filial?: number[]
}

function parseCashier(data: CashierApiData): CashierInfo {
    return {
        id: data.id_caixa,
        name: data.nome,
        hash: data.hash,
        isActive: Boolean(data.flag_ativo),
        branch: data.filial ? parseBranch(data.filial) : null,
    }
}

function parseCashierHistoric(
    data: CashierHistoricApiData,
    coins: CoinInfo[]
): CashierHistoric {
    return {
        id: data.id_caixa_historico,
        cashierId: data.id_caixa,
        status: data.status,
        openingDate: data.data_abertura,
        openingHour: data.hora_abertura,
        closingDate: data.data_fechamento,
        closingHour: data.hora_fechamento,
        isSummerTime: Boolean(data.flag_horario_verao),
        closingIsSummerTime: Boolean(data.flag_horario_verao_fechamento),
        cashier: parseCashier(data.caixa),
        values: data.valoresCaixa.map((v) => ({
            id: v.id_valor_caixa,
            value: v.valor,
            type: v.flag_tipo,
            coin: coins.find((c) => c.id === v.id_moeda),
        })),
    }
}

export class CashierAlreadyInUseError extends Error {}

export async function listCashiers(
    data: ListCashiersInterface
): Promise<ListReturn<CashierInfo>> {
    const res = await post('cashiers', data)
    return {
        data: res.data.data.list.data.map((data: CashierApiData) =>
            parseCashier(data)
        ),
        count: res.data.data.list.count,
    }
}

export async function getCashierById(id: number): Promise<CashierInfo> {
    const res = await get(`cashier/${id}`)
    return parseCashier(res.data.data)
}

export async function createCashier(
    data: CreateCashierInterface
): Promise<void> {
    try {
        await post('cashier', data, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (
            error?.status === 409 &&
            error?.data.data.message.includes(
                'Cashier with hash already exists'
            )
        ) {
            throw new CashierAlreadyInUseError()
        }
        throw error
    }
}

export async function updateCashier(
    data: UpdateCashierInterface
): Promise<void> {
    try {
        await put('cashier', data, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (
            error?.status === 409 &&
            error?.data.data.message.includes(
                'Cashier with hash already exists'
            )
        ) {
            throw new CashierAlreadyInUseError()
        }
        throw error
    }
}

export async function listCashierHistoric(
    { id, page, rows }: ListCashierHistoricInterface,
    coins: CoinInfo[]
): Promise<ListReturn<CashierHistoric>> {
    const res = await post('cashier-history/list', {
        id_caixa: [id],
        skip: page,
        rows: rows,
    })
    return {
        data: res.data.data.list.data.map((data: CashierHistoricApiData) =>
            parseCashierHistoric(data, coins)
        ),
        count: res.data.data.list.count,
    }
}

export async function listExpectedCashierAmount(
    cashierHistoricId: number[],
    coins: CoinInfo[],
    paymentMethods: PaymentMethodInfo[]
): Promise<CashierExpectedAmountInfo> {
    const res = await post('cashier-history/expected-amount', {
        id_caixa_historico: cashierHistoricId,
    })

    return {
        datails: res.data.data.details.map((data: any) => ({
            ...data,
            paymentMethod: parsePaymentMethod(data.paymentMethod),
            coin: parseCoin(data.coin),
        })),
        general: res.data.data.general.map((data: any, index: number) => ({
            ...data,
            id: index + 1,
            paymentMethod: parsePaymentMethod(data.paymentMethod),
            coin: parseCoin(data.coin),
        })),
    }
}

export async function OpenCashier(
    data: ManageCashierInterface
): Promise<number> {
    const res = await post(
        'cashier-history/open',
        {
            id_caixa: data.id,
            valores: data.values,
        },
        {
            useRecaptcha: true,
        }
    )

    return res.data.data.id_caixa_historico
}

export async function CloseCashier(
    data: ManageCashierInterface
): Promise<number> {
    const res = await post(
        'cashier-history/close',
        {
            id_caixa: data.id,
            valores: data.values,
        },
        {
            useRecaptcha: true,
        }
    )

    return res.data.data.id_caixa_historico
}

export async function generateCashierValue(
    data: GenerateCashierValueInterface
): Promise<void> {
    await post('cashier-history/generate-value', data, {
        useRecaptcha: true,
    })
}

export async function LoginCashier(
    data: LoginCashierInterface
): Promise<CashierInfo> {
    try {
        const res = await post('cashier/login', {
            hash_caixa: data.hash_caixa,
        })
        return parseCashier(res.data.data)
    } catch (error: any) {
        if (error?.status === 400 || error?.status === 422)
            throw new InvalidCashierError()
        throw error
    }
}

import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { createDiscount, listDiscounts } from '../services/discount'
import { AccountInfo } from './account_context'
import { useLoading } from './loading_context'
import { ProductInfo } from './product_context'
import { useSnack } from './snack_context'
import { CashierInfo } from './cashier_context'
import { getDateInIsoFormat } from '../utils/maks'

export interface ProductInDiscount {
    id?: number
    products: ProductInfo[]
    discount: number
}

export interface DiscountInfo {
    id?: number
    title: string
    iniD: Date | null
    endD: Date | null
    isActive: boolean
    discountProducts: ProductInDiscount[]
    activeDate?: boolean
    date?: string
    hour?: string
    isSummerTime?: boolean
}

export interface CreateDiscountInfo extends DiscountInfo {
    currentProducts: ProductInDiscount | null
    selectedDiscountProducts: ProductInDiscount[]
    productsCount: number
}

export interface DiscountError {
    title: string
    iniD: string
    endD: string
    currentProducts: string
}

export const discountInfoDefault: CreateDiscountInfo = {
    title: '',
    iniD: null,
    endD: null,
    isActive: true,
    discountProducts: [],
    currentProducts: null,
    selectedDiscountProducts: [],
    productsCount: 0,
}

export const discountErrorDefault: DiscountError = {
    title: '',
    iniD: '',
    endD: '',
    currentProducts: '',
}

export interface DiscountFiltersInterface {
    page: number
    rows: number
    rowsCount: number
    accounts: AccountInfo[]
    cashiers: CashierInfo[]
    date: {
        iniD: Date
        endD: Date
    } | null
    active?: boolean
    dateActive?: boolean
}

export const discountFiltersDefault: DiscountFiltersInterface = {
    page: 1,
    rows: 10,
    rowsCount: 0,
    cashiers: [],
    accounts: [],
    date: null,
}

export interface DiscountContextInterface {
    discounts: DiscountInfo[]
    filters: Partial<DiscountFiltersInterface>
    setFilters: Dispatch<SetStateAction<Partial<DiscountFiltersInterface>>>
    search: string
    setSearch: Dispatch<SetStateAction<string>>
    handleLoadDiscounts(
        filters: Partial<DiscountFiltersInterface>,
        search: string,
        dontSetEmpty?: boolean
    ): Promise<void>
    discountInfo: CreateDiscountInfo
    setDiscountInfo: Dispatch<SetStateAction<CreateDiscountInfo>>
    discountError: DiscountError
    setDiscountError: Dispatch<SetStateAction<DiscountError>>
    validate(): boolean
    handleCreateNewDiscount(): Promise<void>
}

const DiscountContext = createContext<DiscountContextInterface>({
    discounts: [],
    filters: discountFiltersDefault,
    setFilters: () => {},
    search: '',
    setSearch: () => {},
    handleLoadDiscounts: () => Promise.resolve(),
    discountInfo: discountInfoDefault,
    setDiscountInfo: () => {},
    discountError: discountErrorDefault,
    setDiscountError: () => {},
    validate: () => false,
    handleCreateNewDiscount: () => Promise.resolve(),
})

export function useDiscount() {
    return useContext(DiscountContext)
}

interface Props {
    children: ReactNode
}

export const DiscountContextProvider = ({ children }: Props) => {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [discounts, setDiscounts] = useState<DiscountInfo[]>([])
    const [discountInfo, setDiscountInfo] =
        useState<CreateDiscountInfo>(discountInfoDefault)
    const [discountError, setDiscountError] =
        useState<DiscountError>(discountErrorDefault)
    const [filters, setFilters] = useState<Partial<DiscountFiltersInterface>>(
        discountFiltersDefault
    )
    const [search, setSearch] = useState('')

    const validate = () => {
        let isOk = true
        const newDiscountError = { ...discountErrorDefault }

        if (!discountInfo.title) {
            newDiscountError.title = 'Campo obrigatório'
            isOk = false
        }
        if (!discountInfo.iniD) {
            newDiscountError.iniD = 'Campo obrigatório'
            isOk = false
        }
        if (!discountInfo.endD) {
            newDiscountError.endD = 'Campo obrigatório'
            isOk = false
        }
        if (!discountInfo.discountProducts.length) {
            snack.error('Selecione ao menos um produto')
            isOk = false
        }

        setDiscountError({ ...newDiscountError })
        return isOk
    }

    const handleCreateNewDiscount = async () => {
        loading.show()
        try {
            await createDiscount({
                titulo: discountInfo.title,
                data_inicial: discountInfo.iniD
                    ? getDateInIsoFormat(discountInfo.iniD)
                    : '',
                data_final: discountInfo.endD
                    ? getDateInIsoFormat(discountInfo.endD)
                    : '',
                flag_ativo: discountInfo.isActive ? 1 : 0,
                produtos: discountInfo.discountProducts.map((product) => ({
                    id_produtos: product.products.map((p) => p.id ?? 0),
                    valor_desconto: product.discount / 100,
                })),
            })
            setDiscountInfo({ ...discountInfoDefault })
            snack.success('Desconto criado com sucesso!')
            navigate('/system/product/discount')
        } catch (error) {
            snack.connectionFail()
        }
        loading.hide()
    }

    const handleLoadDiscounts = async (
        filters: Partial<DiscountFiltersInterface>,
        search: string,
        dontSetEmpty?: boolean
    ) => {
        loading.logicShow()
        try {
            const res = await listDiscounts({
                skip: filters?.page,
                take: filters?.rows,
                id_caixa: filters?.cashiers?.map((c) => c.id ?? 0),
                id_usuario: filters?.accounts?.map((c) => c.id ?? 0),
                search: search?.length ? search : undefined,
                flag_ativo:
                    typeof filters?.active === 'boolean'
                        ? Number(filters?.active)
                        : undefined,
                data_ativa:
                    typeof filters?.active === 'boolean'
                        ? Number(filters?.dateActive)
                        : undefined,
                data_inicial: filters.date
                    ? getDateInIsoFormat(filters.date.iniD)
                    : undefined,
                data_final: filters.date
                    ? getDateInIsoFormat(filters.date.endD)
                    : undefined,
            })

            if (!dontSetEmpty || res.data.length) {
                setFilters({
                    ...filters,
                    rowsCount: res.count,
                })
                setDiscounts(res.data)
            }
        } catch (error) {
            snack.error('Erro ao carregar descontos')
        }
        loading.logicHide()
    }

    return (
        <DiscountContext.Provider
            value={{
                discounts,
                handleLoadDiscounts,
                filters,
                setFilters,
                search,
                setSearch,
                discountInfo,
                setDiscountInfo,
                discountError,
                setDiscountError,
                validate,
                handleCreateNewDiscount,
            }}
        >
            {children}
        </DiscountContext.Provider>
    )
}

import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react'
import { ImageInterface } from '../pages/Accounts/Register'
import {
    CreateAccountInterface,
    UsernameAlreadyInUseError,
    createAccount,
    getAccountById,
    getLoggedUser,
    listAccountRules,
    updateAccount,
} from '../services/account'
import { BranchInfo } from './branch_context'
import { useConfigs } from './configs_context'
import { useLoading } from './loading_context'
import { useSnack } from './snack_context'

export interface AccountRule {
    id: number
    name: string
    group: string
}

export interface AccountInfo {
    id?: number
    username: string
    name: string
    password: string
    cpassword: string
    rules: AccountRule[]
    isMaster: boolean
    isActive: boolean
    loginWithImage: boolean
    image: ImageInterface | null
    branch: BranchInfo | null
    isEditing?: boolean
}

const createAccountInfoDefault: AccountInfo = {
    username: '',
    name: '',
    password: '',
    cpassword: '',
    rules: [],
    isMaster: false,
    isActive: true,
    loginWithImage: false,
    image: null,
    branch: null,
    isEditing: false,
}

export interface CreateAccountErrorInterface {
    username: string
    name: string
    password: string
    cpassword: string
    branch: string
}

const createAccountErrorDefault: CreateAccountErrorInterface = {
    username: '',
    name: '',
    password: '',
    cpassword: '',
    branch: '',
}

export interface AccountContextInterface {
    accountInfo: AccountInfo | null
    createAccountInfo: AccountInfo
    setCreateAccountInfo: Dispatch<SetStateAction<AccountInfo>>
    createAccountError: CreateAccountErrorInterface
    setCreateAccountError: Dispatch<SetStateAction<CreateAccountErrorInterface>>
    validate(): boolean
    handleCreateNewAccount(): Promise<AccountInfo | null>
    accountRules: AccountRule[]
    acountRuleGroups: string[]
    startNewAccount(basicRules: AccountRule[]): void
    editAccount(id: number, basicRules: AccountRule[]): Promise<void>
}

export const accountContextDefault: AccountContextInterface = {
    accountInfo: null,
    createAccountInfo: createAccountInfoDefault,
    setCreateAccountInfo: () => {},
    createAccountError: createAccountErrorDefault,
    setCreateAccountError: () => {},
    validate: () => false,
    handleCreateNewAccount: async () => {
        return null
    },
    accountRules: [],
    acountRuleGroups: [],
    startNewAccount: () => {},
    editAccount: async () => {},
}

const accountContext = createContext<AccountContextInterface>(
    accountContextDefault
)

export function useAccount() {
    return useContext(accountContext)
}

interface Props {
    children: ReactNode
}

export const AccountContextProvider = ({ children }: Props) => {
    const loading = useLoading()
    const snack = useSnack()
    const [accounts, setAccounts] = useState<AccountInfo[]>([])
    const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null)
    const [createAccountInfo, setCreateAccountInfo] = useState<AccountInfo>(
        createAccountInfoDefault
    )
    const [createAccountError, setCreateAccountError] =
        useState<CreateAccountErrorInterface>(createAccountErrorDefault)
    const [accountRules, setAccountRules] = useState<AccountRule[]>([])
    const [acountRuleGroups, setAccountRuleGroups] = useState<string[]>([])
    const { configs } = useConfigs()

    const getLoggedUserInfo = async () => {
        const token = localStorage.getItem('token')
        if (!token) return

        loading.show()
        try {
            const user = await getLoggedUser(accountRules)
            setAccountInfo(user)
        } catch (error) {
            snack.error('Erro ao carregar as informações do usuário')
        }
        loading.hide()
    }

    useEffect(() => {
        getAccountRules()
    }, [])

    useEffect(() => {
        if (accountRules.length) {
            getLoggedUserInfo()
        }
    }, [accountRules])

    const getAccountRules = async () => {
        loading.show()
        try {
            const res = await listAccountRules()
            setAccountRules(res)

            const newGroups: string[] = []
            res.forEach((rule) => {
                if (newGroups.find((group) => group === rule.group)) return
                newGroups.push(rule.group)
            })
            setAccountRuleGroups(newGroups)
        } catch (error) {
            snack.connectionFail()
        }
        loading.hide()
    }

    const startNewAccount = (basicRules: AccountRule[]) => {
        setCreateAccountInfo({
            ...createAccountInfoDefault,
            rules: basicRules,
        })
        setCreateAccountError({ ...createAccountErrorDefault })
    }

    const editAccount = async (id: number, basicRules: AccountRule[]) => {
        try {
            const account = await getAccountById(id, accountRules)

            setCreateAccountInfo((prev) => ({
                ...account,
                isEditing: true,
                rules: [
                    ...account.rules,
                    ...basicRules.filter(
                        (br) => !account.rules.find((r) => r.id === br.id)
                    ),
                ],
            }))
            setCreateAccountError({ ...createAccountErrorDefault })
        } catch (error) {
            console.log(error)
            snack.error('Conta não encontrada')
            throw new Error('Account not found')
        }
    }

    const validate = (): boolean => {
        let isOk = true
        const newCreateAccountErrorInfo = { ...createAccountErrorDefault }

        if (!createAccountInfo.username) {
            isOk = false
            newCreateAccountErrorInfo.username = 'Campo obrigatório'
        }
        if (!createAccountInfo.name) {
            isOk = false
            newCreateAccountErrorInfo.name = 'Campo obrigatório'
        }
        if (!createAccountInfo.password && !createAccountInfo.isEditing) {
            isOk = false
            newCreateAccountErrorInfo.password = 'Campo obrigatório'
        }
        if (!createAccountInfo.cpassword && !createAccountInfo.isEditing) {
            isOk = false
            newCreateAccountErrorInfo.cpassword = 'Campo obrigatório'
        }
        if (
            createAccountInfo.password &&
            createAccountInfo.password.length < 6
        ) {
            isOk = false
            newCreateAccountErrorInfo.password =
                'Deve conter no mínimo 6 caracteres'
        }
        if (
            createAccountInfo.cpassword &&
            createAccountInfo.cpassword.length < 6
        ) {
            isOk = false
            newCreateAccountErrorInfo.cpassword =
                'Deve conter no mínimo 6 caracteres'
        }
        if (
            createAccountInfo.password.length >= 6 &&
            createAccountInfo.cpassword.length >= 6 &&
            createAccountInfo.password !== createAccountInfo.cpassword
        ) {
            isOk = false
            newCreateAccountErrorInfo.password = 'As senhas não coincidem'
            newCreateAccountErrorInfo.cpassword = 'As senhas não coincidem'
        }
        if (!createAccountInfo.name) {
            isOk = false
            newCreateAccountErrorInfo.name = 'Campo obrigatório'
        }
        /* if (
            accountInfo?.isMaster &&
            !createAccountInfo.isMaster &&
            configs.systemBranch === SystemBranchEnum.Branch &&
            !createAccountInfo.branch
        ) {
            isOk = false
            newCreateAccountErrorInfo.branch = 'Campo obrigatório'
        } */

        setCreateAccountError({ ...newCreateAccountErrorInfo })
        return isOk
    }

    const handleCreateNewAccount = async (): Promise<AccountInfo | null> => {
        let account: AccountInfo | null = null
        loading.show()
        try {
            const body: CreateAccountInterface = {
                username: createAccountInfo.username,
                senha: createAccountInfo.password || undefined,
                nome: createAccountInfo.name,
                flag_ativo: createAccountInfo.isActive ? 1 : 0,
                id_permissions: createAccountInfo.rules.map((r) => r.id),
                flag_login_face: createAccountInfo.loginWithImage ? 1 : 0,
                path_face: createAccountInfo.image?.content ?? null,
                id_filial_master: createAccountInfo.branch?.id,
            }
            if (!createAccountInfo.isEditing) {
                const id = await createAccount(body)
                snack.success('Conta criada com sucesso')
                account = { ...createAccountInfo, id: id }
            } else {
                await updateAccount({
                    ...body,
                    id_usuario: createAccountInfo.id ?? 0,
                })
                snack.success('Conta atualizada com sucesso')
                account = { ...createAccountInfo }
            }
        } catch (error) {
            if (error instanceof UsernameAlreadyInUseError) {
                setCreateAccountError((prev) => ({
                    ...prev,
                    username: 'Usuário já está em uso',
                }))
            } else snack.connectionFail()
            window.scroll(0, 0)
        }
        loading.hide()
        return account
    }

    return (
        <accountContext.Provider
            value={{
                accountInfo,
                createAccountInfo,
                setCreateAccountInfo,
                createAccountError,
                setCreateAccountError,
                validate,
                handleCreateNewAccount,
                accountRules,
                acountRuleGroups,
                startNewAccount,
                editAccount,
            }}
        >
            {children}
        </accountContext.Provider>
    )
}

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { StringField } from '../StringField'

interface Props {
    open: boolean
    handleClose(v: boolean): void
    confirmPassword: boolean
    returnPassword(password: string): void
    err: string
    setErr(v: string): void
}

export const PasswordModal = ({
    open,
    handleClose,
    confirmPassword,
    returnPassword,
    err,
    setErr,
}: Props) => {
    const [pass, setPass] = useState<string>('')
    const [confPass, setConfPass] = useState<string>('')
    const buttonRef = useRef<HTMLButtonElement>(null)
    const [step, setStep] = useState(0)
    const ref = useRef<HTMLInputElement | null>(null)

    const eventListener = (e: KeyboardEvent) => {
        if (e.key === 'Enter') buttonRef.current?.click()
    }

    useEffect(() => {
        setPass('')
        setConfPass('')
        setStep(0)
        setErr('')

        document.body.addEventListener('keydown', eventListener)

        setTimeout(() => {
            ref.current?.focus()
        }, 100)

        return () => {
            document.body.removeEventListener('keydown', eventListener)
        }
    }, [open, confirmPassword])

    useEffect(() => {
        setErr(err)
    }, [err])

    useEffect(() => {
        if (step === 1 && pass !== confPass) setErr('As senhas não são iguais!')
        else setErr('')
    }, [pass, confPass])

    const validate = (): boolean => {
        if (!confirmPassword) {
            if (pass.length < 6) return false
        } else {
            if (pass.length < 6) return false
            if (confPass.length < 6) return false
            if (pass !== confPass) return false
        }

        return true
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            className="modal"
        >
            <DialogTitle>
                <Box
                    sx={{
                        color: 'text.primary',
                        fontSize: '20px',
                        fontWeight: '500',
                        textAlign: 'center',
                        borderBottom: '1px solid rgba(218, 218, 218, 0.7)',
                        paddingBottom: '10px',
                        minWidth: { xs: 'auto', sm: '350px' },
                    }}
                >
                    Senha
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography textAlign={'center'} mb={'10px'}>
                    {step === 0
                        ? 'Para continuar, insire a sua senha'
                        : 'Confirme a sua senha'}
                </Typography>
                <StringField
                    label=""
                    maxLength={50}
                    value={step === 0 ? pass : confPass}
                    setValue={step === 0 ? setPass : setConfPass}
                    ref={ref}
                    sx={{
                        width: '100%',
                    }}
                    error={err}
                    onClick={() => setErr('')}
                    type="password"
                />
                <Button
                    sx={{
                        width: '100%',
                        textTransform: 'none',
                        mt: '20px',
                        p: '10px',
                        fontSize: '16px',
                    }}
                    variant="contained"
                    disabled={
                        step === 0 && confirmPassword
                            ? pass.length < 6
                            : !validate()
                    }
                    ref={buttonRef}
                    onClick={() => {
                        if (step === 0 && confirmPassword) {
                            setStep(1)
                            setTimeout(() => {
                                ref.current?.focus()
                            }, 100)
                        } else {
                            returnPassword(pass)
                        }
                    }}
                >
                    Confirmar
                </Button>
                {step === 1 && (
                    <Button
                        sx={{
                            width: '100%',
                            textTransform: 'none',
                            mt: '20px',
                            p: '10px',
                            fontSize: '16px',
                        }}
                        variant="text"
                        onClick={() => {
                            setStep(0)
                            setPass('')
                            setConfPass('')
                        }}
                    >
                        Voltar
                    </Button>
                )}
            </DialogContent>
        </Dialog>
    )
}

import { anonymousClient } from '../context/client_context'
import { CoinInfo } from '../context/coin_context'
import { ServiceInfo, ServiceTypeInfo } from '../context/service_context'
import { ListReturn } from '../utils/maks'
import { BranchApiData, parseBranch } from './branch'
import { CashierClosedBuyError } from './buy'
import { ClientApiData, parseClient } from './client'
import { get, post, put } from './generic'
import { PaymentMethodApiData, parsePaymentMethod } from './payment'

interface ServiceTypeApiData {
    id_tipo_servico: number
    nome: string
}

function parseServiceType(data: ServiceTypeApiData): ServiceTypeInfo {
    return {
        id: data.id_tipo_servico,
        name: data.nome,
    }
}

export interface CreateServiceInterface {
    id_tipo_servico: number
    id_moeda: number
    valor: number
    data_final: string
    flag_estado: number
    descricao?: string
    id_cliente: number | null
    id_filial_master?: number | null
}

export interface UpdateServiceInterface
    extends Partial<CreateServiceInterface> {
    id_servico: number
}

interface ListServicesInterface {
    skip: number
    take: number
    id_tipo_servico?: number[]
    id_caixa?: number[]
    id_usuario?: number[]
    id_cliente?: number[]
    data_inicial?: string
    data_final?: string
}

interface ServiceApiData {
    id_servico: number
    id_tipo_servico: number
    valor: string
    descricao: string | null
    id_caixa: number
    id_usuario: number
    id_moeda: number
    tipoServico: { nome: string }
    usuario: { nome: string }
    caixa: { nome: string }
    data: string
    hora: string
    flag_horario_verao: number
    data_final: string
    flag_estado: number
    cliente: ClientApiData | null
    filial: BranchApiData | null
}

function parseService(data: ServiceApiData, coins: CoinInfo[]): ServiceInfo {
    return {
        id: data.id_servico,
        account: { id: data.id_usuario, name: data.usuario.nome },
        cashier: { id: data.id_caixa, name: data.caixa?.nome },
        coin: coins.find((c) => c.id === data.id_moeda) ?? null,
        serviceType: { id: data.id_tipo_servico, name: data.tipoServico.nome },
        value: Number(data.valor),
        date: data.data,
        hour: data.hora,
        description: data.descricao ?? undefined,
        isSummerTime: Boolean(data.flag_horario_verao),
        finalDate: new Date(data.data_final),
        serviceStatus: data.flag_estado,
        client: data.cliente ? parseClient(data.cliente) : anonymousClient,
        branch: data.filial ? parseBranch(data.filial) : null,
    }
}

export async function listServiceTypes(): Promise<ServiceTypeInfo[]> {
    const res = await get('service-type')

    return res.data.data.map(parseServiceType)
}

export async function createServiceType(name: string): Promise<number> {
    const res = await post(
        'service-type',
        {
            nome: name,
        },
        {
            useRecaptcha: true,
        }
    )

    return res.data.data.id_tipo_servico
}

export async function createService(
    data: CreateServiceInterface
): Promise<number> {
    try {
        const res = await post('service', data, {
            useRecaptcha: true,
        })
        return res.data.data.id_servico
    } catch (error: any) {
        if (error?.data?.data?.message === 'Cashier is not open')
            throw new CashierClosedBuyError()
        throw error
    }
}

export async function updateService(
    data: UpdateServiceInterface
): Promise<number> {
    try {
        const res = await put('service', data, {
            useRecaptcha: true,
        })
        return res.data.data.id_servico
    } catch (error: any) {
        if (error?.data?.data?.message === 'Cashier is not open')
            throw new CashierClosedBuyError()
        throw error
    }
}

export async function listServices(
    data: ListServicesInterface,
    coins: CoinInfo[]
): Promise<ListReturn<ServiceInfo>> {
    const res = await post('services', data)

    return {
        data: res.data.data.data.map((data: ServiceApiData) =>
            parseService(data, coins)
        ),
        count: res.data.data.count,
    }
}

export async function getServiceById(
    id: number,
    coins: CoinInfo[]
): Promise<ServiceInfo> {
    const res = await get(`service/${id}`)

    return parseService(res.data.data, coins)
}

import { PaymentMethodInfo } from '../context/payment_method_context'
import { get, post, put } from './generic'

export interface PaymentMethodApiData {
    id_metodo_pagamento: number
    nome: string
    flag_ativo: number
}

interface CreatePaymentMethodInterface {
    nome: string
    flag_ativo: number
}

interface UpdatePaymentMethodInterface
    extends Partial<CreatePaymentMethodInterface> {
    id_metodo_pagamento: number
}

export function parsePaymentMethod(
    data: PaymentMethodApiData
): PaymentMethodInfo {
    return {
        id: data.id_metodo_pagamento,
        name: data.nome,
        isActive: Boolean(data.flag_ativo),
    }
}

export async function listPaymentMethods(): Promise<PaymentMethodInfo[]> {
    const res = await get('payment-method')

    return res.data.data.map(parsePaymentMethod)
}

export async function getPaymentMethodById(
    id: number
): Promise<PaymentMethodInfo> {
    const res = await get(`payment-method/${id}`)

    return parsePaymentMethod(res.data.data)
}

export async function craetePaymentMethod(
    data: CreatePaymentMethodInterface
): Promise<void> {
    await post('payment-method', data, {
        useRecaptcha: true,
    })
}

export async function updatePaymentMethod(
    data: UpdatePaymentMethodInterface
): Promise<void> {
    await put('payment-method', data, {
        useRecaptcha: true,
    })
}

import {
    EmployeeInfo,
    EmployeePaymentInfo,
    EmployeePaymentTypeInfo,
} from '../pages/Employee/Home'
import { AccountApiData } from './account'
import { get, post, put } from './generic'
import { ListReturn } from '../utils/maks'
import { BranchApiData, parseBranch } from './branch'
import { CoinInfo } from '../context/coin_context'
import { ComissionReports } from '../components/ComissionDataModal'
import { CashierApiData } from './cashier'

export interface EmployeeApiData {
    id_funcionario: number
    nome: string
    telefone: string
    numero_documento?: string
    salario: string
    comissao: string
    comissao_pagamento: string
    flag_ativo: number
    usuario?: AccountApiData | null
    filial?: BranchApiData | null
    flag_tipo_comissao: number
    ips: string
    remunerassao_familiar: string
    flag_comissao_venda_credito: number
    nome_motorista?: string
    documento_motorista?: string
}

export interface EmployeePaymentTypeApiData {
    id_tipo_pagamento_funcionario: number
    nome: string
}

export interface EmployeePaymentApiData {
    id_pagamento_funcionario: number
    id_tipo_pagamento_funcionario: number
    id_funcionario: number
    data: string
    hora: string
    flag_horario_verao: number
    id_caixa: number | null
    valor: number
    id_moeda: number
    id_usuario: number
    id_caixa_historico: number | null
    usuario: Partial<AccountApiData>
    caixa: Partial<CashierApiData>
    tipoPagamentoFuncionario: EmployeePaymentTypeApiData
    flag_descontar_caixa: number
}

export interface CreateEmployee {
    nome: string
    telefone: string
    numero_documento?: string | null
    salario: number
    comissao: number
    comissao_pagamento: number
    flag_ativo: number
    id_usuario?: number | null
    id_filial_master?: number | null
    flag_tipo_comissao: number
    ips: number
    remunerassao_familiar: number
    flag_comissao_venda_credito: number
    nome_motorista?: string | null
    documento_motorista?: string | null
}

export interface UpdateEmployee extends Partial<CreateEmployee> {
    id_funcionario: number
}

export interface GetComisisonReports {
    id_funcionario: number
    data_inicial: string
    data_final: string
    hora_inicial?: string
    hora_final?: string
}

export interface ListEmployee extends Record<string, any> {
    skip: number
    take: number
    search?: string
    id_filial?: string
}

export interface CreateEmployeePaymentType {
    nome: string
}

export interface CreateEmployeePayment {
    id_funcionario: number
    id_tipo_pagamento_funcionario: number
    valor: number
    flag_descontar_caixa: number
}

export interface ListEmployeePayment {
    id_funcionario: number
    data_inicial?: string
    data_final?: string
}

export function parseEmployee(data: EmployeeApiData): EmployeeInfo {
    return {
        id: data.id_funcionario,
        name: data.nome,
        phoneNumber: data.telefone,
        document: data.numero_documento,
        salary: Number(data.salario),
        comission: Number(data.comissao) * 100,
        paymentComission: Number(data.comissao_pagamento) * 100,
        isActive: !!data.flag_ativo,
        account: data.usuario
            ? {
                  id: data.usuario.id_usuario,
                  username: data.usuario.username,
                  name: data.usuario.nome,
              }
            : null,
        branch: data.filial ? parseBranch(data.filial) : null,
        comissionType: data.flag_tipo_comissao,
        ips: Number(data.ips),
        familiarRenumeration: Number(data.remunerassao_familiar),
        comissionInOpenSale: !!data.flag_comissao_venda_credito,
        driverName: data.nome_motorista,
        driverDocument: data.documento_motorista,
    }
}

export function parseEmployeePaymentType(
    data: EmployeePaymentTypeApiData
): EmployeePaymentTypeInfo {
    return {
        id: data.id_tipo_pagamento_funcionario,
        name: data.nome,
    }
}

export function parseEmployeePayment(
    data: EmployeePaymentApiData,
    coins: CoinInfo[]
): EmployeePaymentInfo {
    return {
        id: data.id_pagamento_funcionario,
        account: {
            id: data.usuario.id_usuario,
            name: data.usuario.nome,
        },
        cashier: {
            id: data.caixa.id_caixa,
            name: data.caixa.nome,
        },
        date: data.data,
        hour: data.hora,
        isSummerTime: !!data.flag_horario_verao,
        value: Number(data.valor),
        employeePaymentType: parseEmployeePaymentType(
            data.tipoPagamentoFuncionario
        ),
        discountFromCashier: !!data.flag_descontar_caixa,
        coin: coins.find((c) => c.id === data.id_moeda),
    }
}

export async function createEmployee(data: CreateEmployee) {
    await post('employee', data, {
        useRecaptcha: true,
    })
}

export async function updateEmployee(data: UpdateEmployee) {
    await put('employee', data, {
        useRecaptcha: true,
    })
}

export async function listEmployee(
    data: ListEmployee
): Promise<ListReturn<EmployeeInfo>> {
    let query = ''
    for (const key of Object.keys(data)) {
        if (data[key] === undefined) continue
        query += `${key}=${data[key]}&`
    }

    const res = await get(`employee?${query}`)

    return {
        data: res.data.data.data.map(parseEmployee),
        count: res.data.data.count,
    }
}

export async function findEmployeeById(id: number): Promise<EmployeeInfo> {
    const res = await get(`employee/${id}`)

    return parseEmployee(res.data.data)
}

export async function getComissionReports(
    data: GetComisisonReports,
    coins: CoinInfo[]
): Promise<ComissionReports[]> {
    const res = await post('employee/comission', data, {
        useRecaptcha: true,
    })

    return res.data.data.map((r: ComissionReports) => ({
        ...r,
        coin: coins.find((c) => c.id === r.coinId),
    }))
}

export async function createEmployeePaymentType(
    data: CreateEmployeePaymentType
): Promise<EmployeePaymentTypeInfo> {
    const res = await post('employee-payment-type', data, {
        useRecaptcha: true,
    })

    return parseEmployeePaymentType(res.data.data)
}

export async function listEmployeePaymentType(): Promise<
    EmployeePaymentTypeInfo[]
> {
    const res = await get('employee-payment-type')
    return res.data.data.map(parseEmployeePaymentType)
}

export async function createEmployeePayment(data: CreateEmployeePayment) {
    await post('employee-payment', data, {
        useRecaptcha: true,
    })
}

export async function listEmployeePayment(
    data: ListEmployeePayment,
    coins: CoinInfo[]
): Promise<EmployeePaymentInfo[]> {
    const res = await post('employee-payment/list', data)
    return res.data.data.map((data: EmployeePaymentApiData) =>
        parseEmployeePayment(data, coins)
    )
}

import React, { forwardRef } from 'react'
import { SxProps, TextField } from '@mui/material'
import { removeExcessSpaces } from '../../utils/maks'

interface Props {
    value: string
    setValue(v: string): void
    label: string
    maxLength?: number
    regex?: RegExp
    sx?: SxProps
    inputSx?: SxProps
    error?: string
    onClick?(): void
    onBlur?(): void
    multiLineRows?: number
    size?: 'small' | 'medium'
    type?: 'text' | 'password'
    endAdornment?: JSX.Element
    disabled?: boolean
}

export const StringField = forwardRef<HTMLInputElement, Props>(
    (
        {
            value,
            setValue,
            label,
            maxLength,
            regex,
            sx,
            inputSx,
            error,
            onClick,
            onBlur,
            multiLineRows,
            size,
            type,
            endAdornment,
            disabled,
        },
        ref
    ) => {
        const getMaxLength = (): number => {
            if (!maxLength) return 0

            const whiteSpaces = value.length - removeExcessSpaces(value).length
            return maxLength + whiteSpaces
        }

        return (
            <TextField
                sx={{
                    ...sx,
                }}
                error={Boolean(error)}
                helperText={error}
                onClick={onClick}
                value={value}
                label={label}
                onChange={(e) => {
                    const { value } = e.target
                    if (value && regex && !regex.test(value)) return
                    setValue(value)
                }}
                onBlur={() => {
                    onBlur && onBlur()
                    if (multiLineRows) return
                    const trimmedValue = removeExcessSpaces(value)
                    setValue(trimmedValue)
                }}
                inputProps={{
                    maxLength: maxLength ? getMaxLength() : undefined,
                }}
                multiline={Boolean(multiLineRows)}
                rows={multiLineRows}
                size={size}
                type={type}
                inputRef={ref}
                disabled={disabled}
                InputProps={{
                    sx: { ...inputSx },
                    endAdornment: endAdornment,
                }}
            />
        )
    }
)

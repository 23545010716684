import React, { useContext, createContext, useState } from 'react'
import { CircularProgress, Modal } from '@mui/material'
import { Box } from '@mui/system'

export interface LoadingInfo {
    show(): void
    hide(): void
    logicShow(): void
    logicHide(): void
    isLoading: boolean
}

const loadingDefault: LoadingInfo = {
    show: () => {},
    hide: () => {},
    logicShow: () => {},
    logicHide: () => {},
    isLoading: false,
}

const LoadingContext = createContext<LoadingInfo>(loadingDefault)

export function useLoading() {
    return useContext(LoadingContext)
}

interface Props {
    children: React.ReactNode
}

export function LoadingProvider({ children }: Props) {
    const [open, setOpen] = useState(0)
    const [logicOpen, setLogicOpen] = useState(0)

    const show = () => {
        setOpen((prev) => prev + 1)
    }

    const hide = () => {
        setOpen((prev) => prev - 1)
    }

    const logicShow = () => {
        setLogicOpen((prev) => prev + 1)
    }

    const logicHide = () => {
        setLogicOpen((prev) => prev - 1)
    }

    return (
        <LoadingContext.Provider
            value={{
                show,
                hide,
                logicShow,
                logicHide,
                isLoading: open > 0 || logicOpen > 0,
            }}
        >
            <Modal open={Boolean(open)} className="modal">
                <Box
                    sx={{
                        transform: 'translate(-50px, -100px)',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        outline: 'none',
                    }}
                >
                    <CircularProgress size="100px" />
                </Box>
            </Modal>
            {children}
        </LoadingContext.Provider>
    )
}

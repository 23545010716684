import { Group } from '../context/product_context'
import { get, post } from './generic'

interface GroupApiData {
    id_grupo: number
    nome: string
    desconto: string
    data_inicio: string
    data_fim: string
}

function ParseGroup(data: GroupApiData): Group {
    return {
        id: data.id_grupo,
        name: data.nome,
    }
}

export async function getAllGroups(): Promise<Group[]> {
    const res = await get('grupos')
    return res.data.data.map((g: GroupApiData) => {
        return ParseGroup(g)
    })
}

export async function getGroupById(id: number): Promise<Group> {
    const res = await post('grupos/specific', {
        filter: [
            {
                name: 'id_grupo',
                value: [id],
            },
        ],
    })

    return ParseGroup(res.data.data.group[0])
}

export async function createGroup(name: string): Promise<Group> {
    const res = await post(
        'grupos',
        {
            nome: name,
            desconto: 0,
            data_inicio: '2023/02/02',
            data_fim: '2020-10-14',
        },
        {
            useRecaptcha: true,
        }
    )
    return ParseGroup(res.data.data.group)
}
